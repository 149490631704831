/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import {
  FormElementListSelectorResult,
  FormElementSelectDynamicRequest,
  WebServiceResponseTyped
} from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormPluginRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormSubmitData } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementInputAsyncValueCallbackRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementInputAsyncValueCallbackResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementAutocompleteRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementAutocompleteCallbackResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementAutocompleteNewCallbackResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementValidationResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementFormatItemsRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementFormatItemsRequestResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementRunCustomCallbackHandlerRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementRunCustomCallbackRequestResult } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class FormService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Get the complete form configuration
  *
  * @param {FormPluginRequest} pluginRequest
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {string} Formstate with the requested form
  */
  postConfiguration(pluginRequest: FormPluginRequest, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest)};
    return this.generalService.post('core-form/configuration', params, clientOpts) as Observable<WebServiceResponseTyped<string>>;
  }

  /**
  *
  * Callback de valor asíncrono de campo
  *
  * @param {string} signedFormState
  * @param {FormSubmitData} formData
  * @param {FormElementInputAsyncValueCallbackRequest} callbackRequest
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementInputAsyncValueCallbackResult}
  */
  postElementasyncvaluecallback(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementInputAsyncValueCallbackRequest, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementInputAsyncValueCallbackResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest)};
    return this.generalService.post('core-form/element-async-value-callback', params, clientOpts) as Observable<WebServiceResponseTyped<FormElementInputAsyncValueCallbackResult>>;
  }

  /**
  *
  * Callback for autocomplete fields
  *
  * @param {string} signedFormState The form state. For security reasons this is stored in the client
  * @param {FormSubmitData} formData User input
  * @param {FormElementAutocompleteRequest} callbackRequest The request
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementAutocompleteCallbackResult} List with autocomplete options
  */
  postFieldautocompletecallback(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementAutocompleteRequest, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementAutocompleteCallbackResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest)};
    return this.generalService.post('core-form/field-autocomplete-callback', params, clientOpts) as Observable<WebServiceResponseTyped<FormElementAutocompleteCallbackResult>>;
  }

  /**
  * @param {string} signedFormState
  * @param {FormSubmitData} formData
  * @param {FormElementAutocompleteRequest} callbackRequest
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementAutocompleteNewCallbackResult}
  */
  postFieldautocompletecallbacknew(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementAutocompleteRequest, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementAutocompleteNewCallbackResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest)};
    return this.generalService.post('core-form/field-autocomplete-callbacknew', params, clientOpts) as Observable<WebServiceResponseTyped<FormElementAutocompleteNewCallbackResult>>;
  }

  /**
   * @param {string} signedFormState
   * @param {FormSubmitData} formData
   * @param {FormElementAutocompleteRequest} callbackRequest
   * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
   * @returns {FormElementAutocompleteNewCallbackResult}
   */
  postFieldselectdynamiccallback(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementSelectDynamicRequest, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementListSelectorResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest)};
    return this.generalService.post('core-form/field-selectdynamyc-callbacknew', params, clientOpts) as Observable<WebServiceResponseTyped<FormElementListSelectorResult>>;
  }

  /**
  *
  * Run validation handlers for a field
  *
  * @param {string} signedFormState The form state. For security reasons this is stored in the client
  * @param {FormSubmitData} formData User input
  * @param {string} fieldSelector Field selector
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementValidationResult} Valid FormElement object
  */
  postFieldvalidate(signedFormState: string, formData: FormSubmitData, fieldSelector: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementValidationResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'fieldSelector' : fieldSelector};
    return this.generalService.post('core-form/field-validate', params, clientOpts) as Observable<WebServiceResponseTyped<FormElementValidationResult>>;
  }

  /**
  *
  * Callback for autocomplete fields
  *
  * @param {string} signedFormState The form state. For security reasons this is stored in the client
  * @param {FormSubmitData} formData User input
  * @param {FormElementFormatItemsRequest} callbackRequest The request
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementFormatItemsRequestResult} List with autocomplete options
  */
  postFormformatitems(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementFormatItemsRequest, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementFormatItemsRequestResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest)};
    return this.generalService.post('core-form/form-format-items', params, clientOpts) as Observable<WebServiceResponseTyped<FormElementFormatItemsRequestResult>>;
  }

  /**
  *
  * Callback for autocomplete fields
  *
  * @param {string} signedFormState The form state. For security reasons this is stored in the client
  * @param {FormSubmitData} formData User input
  * @param {FormElementRunCustomCallbackHandlerRequest} callbackRequest The request
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementRunCustomCallbackRequestResult} List with autocomplete options
  */
  postFormruncustomcallback(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementRunCustomCallbackHandlerRequest, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementRunCustomCallbackRequestResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest)};
    return this.generalService.post('core-form/form-run-custom-callback', params, clientOpts) as Observable<WebServiceResponseTyped<FormElementRunCustomCallbackRequestResult>>;
  }

  /**
  *
  * Submit a form
  *
  * @param {string} signedFormState
  * @param {FormSubmitData} formData All user input
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {string} FormState object
  */
  postSubmit(signedFormState: string, formData: FormSubmitData, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData)};
    return this.generalService.post('core-form/submit', params, clientOpts) as Observable<WebServiceResponseTyped<string>>;
  }
}
