import { CORE_QUEUE } from '../models/ETG_SABENTISpro_Models_models';

export interface ITypedObject {
  readonly $type: string;
}

/**
 * CoreModule Permissions (contants)
 */
export class CoreModulePermissions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.CoreModulePermissions';
  public static readonly PERMISSION_AUTHENTICATED: string = 'authenticated';
  public static readonly AdvancedDeveloperOptions: string = 'advanced developer options';
  public static readonly ManageSystemModules: string = 'manage system modules';
  public static readonly DeleteModuleData: string = 'delete module data';
  public static readonly ManageCronJobs: string = 'core manage cron jobs';
  public static readonly SeeSystemInfo: string = 'see system info';
  public static readonly ManageUsers: string = 'manage users';
  public static readonly ManageSuperGroups: string = 'manage super groups';
  public static readonly SeeErrorDetails: string = 'see error details';
  public static readonly ManagePermissions: string = 'manage permissions';
  public static readonly ManagePermissionsVisibility: string = 'manage permissions visibility';
  public static readonly ManageTranslations: string = 'manage translations';
  public static readonly SeeSystemConfiguration: string = 'see system configuration';
  public static readonly ManageSystemConfiguration: string = 'manage system configuration';
  public static readonly ManageSystemLockedConfiguration: string = 'manage system locked configuration';
  public static readonly ActivateSystemConfiguration: string = 'activate system configuration';
  public static readonly ChangeContext: string = 'change context';
  public static readonly RestoreUserPassword: string = 'restore user password';
  public static readonly ManageMaintenanceMode: string = 'manage maintenance mode';
  public static readonly SeeMyOwnInfo: string = 'see my own info';
  public static readonly SeeConfiguraton: string = 'see configuration';
  public static readonly UploadFiles: string = 'upload files';
  public static readonly DownloadFiles: string = 'download files';
  public static readonly EditFiles: string = 'edit files';
  public static readonly RemoveFiles: string = 'remove files';
  public static readonly ImpersonateUsers: string = 'impersonate users';
  public static readonly SeeUtilities: string = 'see utilities';
  public static readonly SeeSpreadSheetImports: string = 'see spreadsheet imports';
  public static readonly SeeSpreadSheetImportsSystem: string = 'see spreadsheet imports system';
  public static readonly ManageSpreadSheetImports: string = 'manage spreadsheet imports';
  public static readonly ManageSystemSpreadSheetImports: string = 'manage system spreadsheet imports';
  public static readonly ExecuteSpreadSheetImports: string = 'execute spreadsheet imports';
  public static readonly ExecuteSpreadSheetImportsSystem: string = 'execute spreadsheet imports system';
  public static readonly ExecuteSpreadSheetExports: string = 'execute spreadsheet exports';
  public static readonly SeeSessionUi: string = 'see sessionui';
  public static readonly CloseSessionUi: string = 'close sessionui';
  public static readonly SeeFunctionalParametrics: string = 'see functional parametrics';
  public static readonly SeeOwnBatchTasks: string = 'see own batch tasks';
  public static readonly SeeIconOwnBatchTasks: string = 'see icon own batch tasks';
  public static readonly SeeSystemBatchTasks: string = 'see system batch tasks';
  public static readonly ManageLanguage: string = 'core manage language';
  public static readonly AddProfileGroup: string = 'core profile group add';
  public static readonly EditProfileGroup: string = 'core profile group edit';
  public static readonly DeleteProfileGroup: string = 'core profile group delete';
  public static readonly SeeModuleProfileGroup: string = 'core module profile group see';
  public static readonly SeeGroupProfile: string = 'core see group profile';
  public static readonly SeePersonManagement: string = 'core person management see';
  public static readonly SeePersonManagementList: string = 'core person list see';
  public static readonly EditPerson: string = 'core person edit';
  public static readonly CreatePerson: string = 'core person create';
  public static readonly AssignProfilesToPerson: string = 'core person assign profile';
  public static readonly ProcessGroupConfiguration: string = 'core process group configure';
  public static readonly SeeAppTracking: string = 'core see app tracking';
  public static readonly ManageSystemQuartz: string = 'manage system quartz';
  public static readonly ManageSystemGoogleMaps: string = 'manage system google maps';
  public static readonly SeeChangelog: string = 'see changelog';
  public static readonly SeePrivacyPolicyInMenu: string = 'see privacy policy';
  public static readonly SeeHelpdesk: string = 'core helpdesk see';
  public static readonly ManageBasicParametricContents: string = 'core basicparametric manage';
  readonly $type: string = CoreModulePermissions.$type;
}


/**
 * StatusItemInterface
 */
export interface IStatusItem extends ITypedObject {
  Title: string;
  Value: string;
  Description: string;
  Severity: StatusSeverity;
  Href: string;
}


/**
 * Enum status severity message:
 */
export enum StatusSeverity {
  Info = 0,
  Ok = 1,
  Warning = 2,
  Error = 3
}


/**
 * Status Item
 */
export class StatusItem implements IStatusItem {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Status.StatusItem';
  readonly $type: string = StatusItem.$type;
  Title: string;
  Value: string;
  Description: string;
  Severity: StatusSeverity;
  Href: string;
}


/**
 * Interface with the access property
 */
export interface IWeightProperty extends ITypedObject {
  Weight: number;
}


/**
 * Represents a client command
 */
export interface ICommand extends IWeightProperty, ITypedObject {
  Sync: boolean;
  Async: boolean;
  Id: string;
}


/**
 * Comando para impersonar a un usuario
 */
export class CoreSessionImpersonateCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Session.CoreSessionImpersonateCommand';
  readonly $type: string = CoreSessionImpersonateCommand.$type;
  UserId: string;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Errors in Authentication. This constants are used in front
 */
export class AuthenticationErrors implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Session.Enums.AuthenticationErrors';
  public static readonly UserNotFound: string = 'login-user-not-found';
  public static readonly WrongPassword: string = 'login-wrong-password';
  public static readonly UserWithoutPassword: string = 'login-user-without-password';
  public static readonly UserPasswordExpired: string = 'login-user-password-expired';
  readonly $type: string = AuthenticationErrors.$type;
}


/**
 * Session Authentication Status
 */
export enum AuthenticationStatusEnums {
  NoAuthenticated = 0,
  AuthenticatedWithoutPerson = 1,
  Authenticated = 2
}


/**
 * Wrapper para información de sesión
 */
export interface ISessionInfo extends ITypedObject {
  UserId?: string;
  PersonId?: string;
  PersonMail: string;
  TenantId?: string;
  TenantName: string;
  Identification: string;
  PersonFullName: string;
  AuthenticationStatus?: any;
  Groups: string[];
  IsImpersonatedSession?: boolean;
}


/**
 * Comando para cerrar la sesión del usuario
 */
export class CoreSessionLogoutCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Session.Command.CoreSessionLogoutCommand';
  readonly $type: string = CoreSessionLogoutCommand.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 */
export class ICoreRenderTemplate implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.RenderTemplate.ICoreRenderTemplate';
  readonly $type: string = ICoreRenderTemplate.$type;
}


/**
 * Css classes
 */
export interface ICssClassesProperty extends ITypedObject {
  CssClasses: string[];
}


/**
 * Render a simple message list
 */
export class CoreRenderTemplateSimpleMessageTemplate extends ICoreRenderTemplate implements ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.RenderTemplate.Dto.CoreRenderTemplateSimpleMessageTemplate';
  readonly $type: string = CoreRenderTemplateSimpleMessageTemplate.$type;
  CssClasses: string[];
  Messages: CoreRenderTemplateSimpleMessageMessage[];
}


/**
 */
export class CoreRenderTemplateSimpleMessageMessage implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.RenderTemplate.Dto.CoreRenderTemplateSimpleMessageMessage';
  readonly $type: string = CoreRenderTemplateSimpleMessageMessage.$type;
  Type: CoreRenderTemplateSimpleMessageType;
  Message: string;
}


/**
 */
export enum CoreRenderTemplateSimpleMessageType {
  Info = 0,
  Warning = 1,
  Error = 2,
  Success = 3
}


/**
 * Classes with a title
 */
export interface ITitleProperty extends ITypedObject {
  Title: string;
}


/**
 * Clase para modelar los atributo generales de modales en frontend, también sirve para el sidebar (debe ser intercambiable!)
 */
export class DtoFrontendModal implements ICssClassesProperty, ITitleProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Modal.DtoFrontendModal';
  readonly $type: string = DtoFrontendModal.$type;
  HideHeader: boolean;
  HideClose: boolean;
  CssClasses: string[];
  Title: string;
  ModalSize: DtoFrontendModalSize;
  ModalType: DtoFrontendModalType;
}


/**
 * Commando para mostrar una modal de confirmación/rechazo
 */
export class CoreModalConfirmMessageCommand extends DtoFrontendModal implements ICssClassesProperty, ITitleProperty, ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Modal.CoreModalConfirmMessageCommand';
  readonly $type: string = CoreModalConfirmMessageCommand.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
  NoLabel: string;
  YesLabel: string;
  HideNoButton: boolean;
  HideYesButton: boolean;
  Message: string;
}


/**
 * The predefined set of modal sizes.
 */
export enum DtoFrontendModalSize {
  Small = 0,
  Medium = 1,
  Large = 2,
  ExtraLage = 3
}


/**
 * The predefined set of modal sizes.
 */
export enum DtoFrontendModalType {
  Auto = 0,
  Modal = 1,
  Sidebar = 2
}


/**
 * MappingEvents
 */
export class MappingEvents implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Mapping.Events.MappingEvents';
  public static readonly AfterBuildDefinition: string = 'AfterBuildDefinition';
  readonly $type: string = MappingEvents.$type;
}


/**
 * Interfaz para comandos con argumentos
 */
export interface ICommandWithArguments extends ICommand, ITypedObject {
  ArgumentValues: any[];
}


/**
 * Open a form in a modal
 */
export class CoreMappingOpenInModalCommand extends DtoFrontendModal implements ICssClassesProperty, ITitleProperty, ICommandWithArguments {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Mapping.Plugins.Command.CoreMappingOpenInModalCommand';
  readonly $type: string = CoreMappingOpenInModalCommand.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
  AvailableMappings: CoreMappingRequest[];
  OnCloseCommands: { [id: string]: ICommand };
  ArgumentValues: any[];
}


/**
 * Configuración de petición de ejecución de un mapping
 */
export class CoreMappingRequest implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Mapping.Dto.CoreMappingRequest';
  readonly $type: string = CoreMappingRequest.$type;
  MappingId: string;
  MappingArguments: { [id: string]: any };
  ExecutionFormId: string;
}


/**
 * Mapping Definition
 */
export class MappingDefinitionNonGeneric implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Mapping.Dto.MappingDefinition';
  readonly $type: string = MappingDefinitionNonGeneric.$type;
  Revision: number;
  SourceType: MappingDefinitionSourceEnum;
  TargetTypeFullName: string;
  ImportPluginId: string;
  ExportPluginId: string;
  Elements: any[];
  Filters: any;
  Permissions: string[];
  DBModelContextType: string;
}


/**
 * Mapping Definition
 */
export class MappingDefinition<T> extends MappingDefinitionNonGeneric implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Mapping.Dto.MappingDefinition\`1';
  readonly $type: string = MappingDefinition.$type;
  TargetTypeFullName: string;
  Target: any;
}


/**
 * Mapping Source Types
 */
export enum MappingDefinitionSourceEnum {
  MappingPlugin = 0,
  Entity = 1,
  Form = 2,
  List = 3
}


/**
 */
export class CoreMaintenanceCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Maintenance.Command.CoreMaintenanceCommand';
  readonly $type: string = CoreMaintenanceCommand.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 */
export class GeocodingConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Geocoding.Dto.GeocodingConfiguration';
  readonly $type: string = GeocodingConfiguration.$type;
  ApiKey: string;
  Language: string;
  Longitude?: number;
  Latitude?: number;
  Country: string;
}


/**
 */
export interface IGeocodeResult extends ITypedObject {
}


/**
 */
export class ReverseGeocodeResult implements IGeocodeResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Geocoding.Dto.ReverseGeocodeResult';
  readonly $type: string = ReverseGeocodeResult.$type;
  Id: string;
  FormattedAddress: string;
  AddressMetadata: any;
  RawResult: string;
}


/**
 */
export class GeocodeResult implements IGeocodeResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Geocoding.Dto.GeocodeResult';
  readonly $type: string = GeocodeResult.$type;
  Id: string;
  FormattedAddress: string;
  Longitude: number;
  Latitude: number;
  PartialMatch: boolean;
  AddressMetadata: any;
  RawResult: string;
}


/**
 * Available file Schemes
 */
export class FileSchemeEnum implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Disk.FileSchemeEnum';
  public static readonly Public: string = 'public';
  public static readonly Private: string = 'private';
  public static readonly Log: string = 'log';
  public static readonly Temp: string = 'temporary';
  public static readonly App: string = 'app';
  readonly $type: string = FileSchemeEnum.$type;
}


/**
 * Posibles extensiones para el output de un documento generado en el sistema.
 */
export class FileExtensions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Reports.FileExtensions';
  public static readonly Pdf: string = '.pdf';
  public static readonly Doc: string = '.doc';
  public static readonly Docx: string = '.docx';
  readonly $type: string = FileExtensions.$type;
}


/**
 * Schedule a new Report
 */
export class CoreCreateReportCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Reports.Command.CoreCreateReportCommand';
  readonly $type: string = CoreCreateReportCommand.$type;
  Target: string;
  Arguments: any;
  SeeProgress: boolean;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Used to store information about installed modules. Will be serialized to the variable table.
 */
export class InstalledModule implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.ModuleManager.InstalledModule';
  readonly $type: string = InstalledModule.$type;
  name: string;
  status: boolean;
  missing: boolean;
  moduleStatus?: any;
}


/**
 */
export class ViewExecutableCountResult implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewExecutableCountResult';
  readonly $type: string = ViewExecutableCountResult.$type;
  NoResultQuery?: boolean;
  EmptyResultSet?: boolean;
  EmptyResultSetCalculationExecuted: boolean;
  Count?: number;
  CountType?: any;
}


/**
 * Interface with the access property
 */
export interface IAccessProperty extends ITypedObject {
  Access?: boolean;
}


/**
 * Interface with the access property
 */
export interface ITagsProperty extends ITypedObject {
  Tags: string[];
}


/**
 * Configuration for a view
 */
export class ViewConfiguration implements ITitleProperty, IAccessProperty, ITagsProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewConfiguration';
  readonly $type: string = ViewConfiguration.$type;
  ViewId: string;
  AllowAnonymousExecution: boolean;
  AllowViewStorage: boolean;
  ViewStorageKey: string;
  Title: string;
  InternalTitle: string;
  NoResultText: string;
  AccessCallback: any;
  ViewsDataSourceConfig: any;
  UnionSources: { [id: string]: any };
  Pager: IViewsPager;
  PrimaryKeyField: string;
  Fields: { [id: string]: IViewField };
  Sorts: { [id: string]: IViewsSort };
  Processors: { [id: string]: IViewProcessor };
  QuickSearch: ViewQuickSearch;
  AvailableViewModes: { [id: string]: IViewMode };
  DefaultViewMode: string;
  GroupBy: IViewGroupBy;
  Access?: boolean;
  ViewHeader: IViewHeader;
  FilterManagers: { [id: string]: IViewFilterManager };
  LocalActions: { [id: string]: IViewsLocalAction };
  Operations: { [id: string]: IVboOperation };
  OperationsConfiguration: any;
  DefaultUserConfiguration: ViewUserConfiguration;
  OnEmptyResultSetCommandsOnLoad: { [id: string]: ICommand };
  OnEmptyResultSetRender: ICoreRenderTemplate;
  RowPostProcessCallback: any;
  ViewPostProcessCallback: any;
  Tags: string[];
  PluginRequest: ViewsPluginRequest;
}


/**
 * Permite definir "plugins" que se cuelgen del flujo de ejecución de una vista
 */
export interface IViewProcessor extends IAccessProperty, IWeightProperty, ITypedObject {
  Id: string;
}


/**
 * A views sort
 */
export interface IViewsSort extends IViewProcessor, ITypedObject {
}


/**
 * Sort direction for a list field
 */
export enum ViewSortDirection {
  None = 0,
  Ascending = 1,
  Descending = 2
}


/**
 * Configuration for a single predefined filter
 */
export class ViewsPredefinedFilter implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.PredefinedFilterSet.ViewsPredefinedFilter';
  readonly $type: string = ViewsPredefinedFilter.$type;
  Id: string;
  Filter: any;
  Label: string;
  Description: string;
}


/**
 * Permite definir una serie de filtros predefinidos que el usuario selecciona en frontend
 */
export class ViewsPredefinedFilterSet implements IViewProcessor {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.PredefinedFilterSet.ViewsPredefinedFilterSet';
  public static readonly ProcessorKey: string = 'view_predefined_filterset';
  readonly $type: string = ViewsPredefinedFilterSet.$type;
  AllowMultipleSelection: boolean;
  JoinConstraintsWithOr: boolean;
  Filters: { [id: string]: ViewsPredefinedFilter };
  DefaultSelection: string[];
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 */
export interface IViewsUserConfiguration extends ITypedObject {
  Id: string;
}


/**
 */
export class ViewsPredefinedFilterSetUserConfig implements IViewsUserConfiguration {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.PredefinedFilterSet.ViewsPredefinedFilterSetUserConfig';
  readonly $type: string = ViewsPredefinedFilterSetUserConfig.$type;
  Id: string;
  SelectedFilters: string[];
}


/**
 */
export class ViewsFieldTypeConfigEnumOption implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.FieldTypeConfig.ViewsFieldTypeConfigEnumOption';
  readonly $type: string = ViewsFieldTypeConfigEnumOption.$type;
  Id: string;
  Match: any;
  DisplayName: string;
}


/**
 * Type related information for fields that come from a data source that can be filtered/sorted (ViewFieldData)
 */
export interface IViewsFieldTypeConfig extends ITypedObject {
  ClrType: ViewsFieldsClrTypeEnum;
  Type: ViewsFieldTypeEnum;
}


/**
 * Enum for .Net simple types
 */
export enum ViewsFieldsClrTypeEnum {
  String = 0,
  Double = 1,
  Float = 2,
  Int = 3,
  Guid = 4,
  Short = 5,
  Long = 6,
  Boolean = 7,
  Byte = 8,
  Object = 9,
  DateTimeOffset = 10,
  DateTime = 11,
  TimeSpan = 12,
  ByteArray = 13,
  Decimal = 14,
}


/**
 * Types for user input / frontend
 */
export enum ViewsFieldTypeEnum {
  String = 0,
  Number = 1,
  Date = 2,
  Boolean = 3,
  Enum = 4,
  Object = 5,
  DateTime = 6
}


/**
 */
export class ViewsFieldTypeConfigEnum implements IViewsFieldTypeConfig {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.FieldTypeConfig.ViewsFieldTypeConfigEnum';
  readonly $type: string = ViewsFieldTypeConfigEnum.$type;
  Type: ViewsFieldTypeEnum;
  ClrType: ViewsFieldsClrTypeEnum;
  Options: { [id: string]: ViewsFieldTypeConfigEnumOption };
}


/**
 * Respresenta un Match en las opciones de ViewsFieldTypeConfigEnumOption de valor no encontrado/DbNull
 */
export class ViewsFieldTypeConfigEnumOptionNoValue implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.FieldTypeConfig.ViewsFieldTypeConfigEnumOptionNoValue';
  public static readonly NoValueOrNullMatch: string = 'NoValueOrNullMatch';
  readonly $type: string = ViewsFieldTypeConfigEnumOptionNoValue.$type;
}


/**
 */
export class ViewsFieldTypeConfigSimpleType implements IViewsFieldTypeConfig {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.FieldTypeConfig.ViewsFieldTypeConfigSimpleType';
  readonly $type: string = ViewsFieldTypeConfigSimpleType.$type;
  ClrType: ViewsFieldsClrTypeEnum;
  Type: ViewsFieldTypeEnum;
}


/**
 * Views metadata objects
 */
export interface IViewsMetadata extends ITypedObject {
}


/**
 * Metadata for an entity in views
 */
export class ViewsEntityMetadata implements IViewsMetadata {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Entity.ViewsEntityMetadata';
  readonly $type: string = ViewsEntityMetadata.$type;
  PrimaryKeys: { [id: string]: string };
  EntityName: string;
}


/**
 * User List Configuration
 */
export class ViewUserConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.UserConfiguration.ViewUserConfiguration';
  readonly $type: string = ViewUserConfiguration.$type;
  Search: ViewsQuickSearchUserConfiguration;
  CurrentViewModeType?: any;
  RetrieveKeys: CoreHashedKey[];
  AvailableUserViewModes: { [id: string]: IViewModeUserConfiguration };
  Pagination: IViewsPagerUserConfiguration;
  Filters: { [id: string]: IViewFilerInstance };
  UserConfigurations: { [id: string]: IViewsUserConfiguration };
  CurrentViewMode: IViewModeUserConfiguration;
}


/**
 * Specific user configuration for the current view mode
 */
export interface IViewModeUserConfiguration extends ITypedObject {
  Id: string;
}


/**
 * Specific user configuration for the current view mode
 */
export interface IViewModeUserConfigurationColumnsBased extends IViewModeUserConfiguration, ITypedObject {
  Columns: IViewModeUserConfigurationColumn[];
  SingleFieldSort: ViewModeColumnBasedFieldSort;
}


/**
 * User Configuration: View Mode with Columns
 */
export class ViewModeUserConfigurationColumnBased implements IViewModeUserConfigurationColumnsBased {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.UserConfiguration.ViewModeColumnBased.ViewModeUserConfigurationColumnBased';
  readonly $type: string = ViewModeUserConfigurationColumnBased.$type;
  Id: string;
  Columns: IViewModeUserConfigurationColumn[];
  SingleFieldSort: ViewModeColumnBasedFieldSort;
}


/**
 */
export class ViewModeTableUserConfiguration extends ViewModeUserConfigurationColumnBased implements IViewModeUserConfigurationColumnsBased {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.UserConfiguration.ViewModeTable.ViewModeTableUserConfiguration';
  readonly $type: string = ViewModeTableUserConfiguration.$type;
  Id: string;
}


/**
 */
export interface IViewModeUserConfigurationField extends ITypedObject {
  Field: string;
  Visible: boolean;
  Weight: number;
}


/**
 */
export interface IViewModeUserConfigurationSortableField extends IViewModeUserConfigurationField, ITypedObject {
  SortDirection: ViewSortDirection;
}


/**
 */
export interface IViewModeUserConfigurationColumn extends IViewModeUserConfigurationSortableField, ITypedObject {
}


/**
 * Vincular campos a columnas
 */
export class ViewModeTableUserConfigurationColumn implements IViewModeUserConfigurationColumn {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.UserConfiguration.ViewModeTable.ViewModeTableUserConfigurationColumn';
  readonly $type: string = ViewModeTableUserConfigurationColumn.$type;
  Field: string;
  SortDirection: ViewSortDirection;
  Visible: boolean;
  Weight: number;
}


/**
 */
export class ViewModeCalendarUserConfiguration extends ViewModeUserConfigurationColumnBased implements IViewModeUserConfigurationColumnsBased {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.UserConfiguration.ViewModeCalendar.ViewModeCalendarUserConfiguration';
  readonly $type: string = ViewModeCalendarUserConfiguration.$type;
  Id: string;
  CurrentCalendarMode: string;
  CurrentDate: Date;
}


/**
 */
export class ViewModeCardBoardsUserConfiguration extends ViewModeUserConfigurationColumnBased implements IViewModeUserConfigurationColumnsBased {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.UserConfiguration.ViewModeCardBoards.ViewModeCardBoardsUserConfiguration';
  readonly $type: string = ViewModeCardBoardsUserConfiguration.$type;
  Id: string;
}


/**
 * Vincular campos a columnas
 */
export class ViewModeCardBoardsUserConfigurationColumn implements IViewModeUserConfigurationColumn {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.UserConfiguration.ViewModeCardBoards.ViewModeCardBoardsUserConfigurationColumn';
  readonly $type: string = ViewModeCardBoardsUserConfigurationColumn.$type;
  Field: string;
  SortDirection: ViewSortDirection;
  Visible: boolean;
  Weight: number;
}


/**
 */
export class ViewModeColumnBasedFieldSort implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.UserConfiguration.ViewModeColumnBased.ViewModeColumnBasedFieldSort';
  readonly $type: string = ViewModeColumnBasedFieldSort.$type;
  Field: string;
  Direction: ViewSortDirection;
}


/**
 */
export class ViewQuickSearch implements IViewProcessor {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.QuickSearch.ViewQuickSearch';
  readonly $type: string = ViewQuickSearch.$type;
  Fields: ViewQuickSearchFieldSimple[];
  SearchStrategy: ViewsQuickSearchFieldSearchStrategy;
  MaxConcurrentFields: number;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Search strategy for quick search field
 */
export enum ViewsQuickSearchFieldSearchStrategy {
  Contains = 0,
  ContainsAllWords = 1
}


/**
 */
export interface IViewQuickSearchField extends ITypedObject {
  Name: string;
}


/**
 * Represents a field that is taken from a ViewFieldData
 */
export class ViewQuickSearchFieldSimple implements IViewQuickSearchField {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.QuickSearch.ViewQuickSearchFieldSimple';
  readonly $type: string = ViewQuickSearchFieldSimple.$type;
  FieldId: string;
  CheckedByDefault: boolean;
  Name: string;
}


/**
 */
export interface IViewsQuickSearchUserConfiguration extends ITypedObject {
}


/**
 */
export class ViewsQuickSearchUserConfiguration implements IViewsQuickSearchUserConfiguration {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.QuickSearch.ViewsQuickSearchUserConfiguration';
  readonly $type: string = ViewsQuickSearchUserConfiguration.$type;
  SearchString: string;
  Fields: string[];
}


/**
 * An instance of a filter for the view
 */
export interface IViewFilerInstance extends ITypedObject {
  Id: string;
}


/**
 * Instancia de filtro simple, se utiliza para los filtros ad-hoc de front. Se apalanca en los campos/fields configurados en el listado para obtener los selectores. Solo campos del tipo ViewFieldData.
 */
export class ViewFilterInstanceSimple implements IViewFilerInstance {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Filter.ViewFilterInstanceSimple';
  readonly $type: string = ViewFilterInstanceSimple.$type;
  Id: string;
  FilterId: string;
  Operator: ViewFilterSimpleOperatorType;
  Value: any;
  Value2: any;
  Negate: boolean;
}


/**
 * Types of operators for the view simple filter manager
 */
export enum ViewFilterSimpleOperatorType {
  Equals = 0,
  Contains = 1,
  StartsWith = 2,
  EndsWith = 3,
  InRange = 4,
  GreaterThan = 5,
  SmallerThan = 6,
  ContainedInCsv = 7,
  Empty = 8,
  GreaterThanOrEqual = 9,
  SmallerThanOrEqual = 10
}


/**
 * Interface for filter managers
 */
export interface IViewFilterManager extends IViewProcessor, ITypedObject {
  Type: string;
}


/**
 * The manager for simple filters. Todo esto de los simples filters está MUY acoplado, se ha hecho así a posta para ir rápido.  La arquitectura base de listados estaría preparada para crecer e implementar algo más desacoplado.
 */
export class ViewFilterManagerSimple implements IViewFilterManager {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Filter.ViewFilterManagerSimple';
  readonly $type: string = ViewFilterManagerSimple.$type;
  Filters: { [id: string]: ViewFilterSimple };
  OperatorConfiguration: { [id: string]: ViewFilterSimpleOperatorConfiguration };
  OperatorsForType: ViewsDataTypeConfiguration[];
  DefaultUserConfigurationFilters: { [id: string]: IViewFilerInstance };
  Type: string;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 */
export enum ViewFilterManagerTypeEnum {
  Simple = 0,
  Query = 1
}


/**
 * Filter configuration for a simple filter. Defines the available filters for the ViewFilterManagerSimple
 */
export class ViewFilterSimple implements IAccessProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Filter.ViewFilterSimple';
  readonly $type: string = ViewFilterSimple.$type;
  Id: string;
  Field: string;
  Label: string;
  Access?: boolean;
  AccessCallback: any;
  Options: { [id: string]: ViewsFieldTypeConfigEnumOption };
}


/**
 * The configuration for all the operators
 */
export class ViewFilterSimpleOperatorConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Filter.ViewFilterSimpleOperatorConfiguration';
  readonly $type: string = ViewFilterSimpleOperatorConfiguration.$type;
  Id: string;
  Type: ViewFilterSimpleOperatorType;
  Label: string;
  Description: string;
}


/**
 * Used to represent the available options for a multi-value filter
 */
export class ViewFilterSimpleValueOption implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Filter.ViewFilterSimpleValueOption';
  readonly $type: string = ViewFilterSimpleValueOption.$type;
  Key: string;
  Value: string;
}


/**
 * Se utiliza únicamente en front para montar los operadores y tipos de datos disponibles en cada caso.
 */
export class ViewsDataTypeConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Filter.ViewsDataTypeConfiguration';
  readonly $type: string = ViewsDataTypeConfiguration.$type;
  DataType: ViewsFieldTypeEnum;
  Operators: ViewFilterSimpleOperatorType[];
}


/**
 */
export interface ILinkColumn extends ITypedObject {
  Id: string;
}


/**
 */
export class LinkColumnFile implements ILinkColumn {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.DisplayOptions.LinkColumnFile';
  readonly $type: string = LinkColumnFile.$type;
  Id: string;
  RenderAsLinkUriExpression: any;
}


/**
 */
export class LinkColumnNavigationNode implements ILinkColumn {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.DisplayOptions.LinkColumnNavigationNode';
  readonly $type: string = LinkColumnNavigationNode.$type;
  Id: string;
  Controller: string;
  StaticArguments: { [id: string]: string };
  DynamicArguments: { [id: string]: string };
}


/**
 */
export class RenderAsLinkConstants implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.DisplayOptions.RenderAsLinkConstants';
  public static readonly MetaKeyRenderAsLink: string = 'field_meta_renderAsLink';
  public static readonly MetaKeyLinkColumnFile: string = 'field_meta_LinkColumnFile';
  public static readonly MetaKeyLinkColumnNavigationNode: string = 'field_meta_LinkColumnNavigationNode';
  readonly $type: string = RenderAsLinkConstants.$type;
}


/**
 * The base interface ViewMode Field, it could be based on column paradigm or not
 */
export interface IViewModeField extends ICssClassesProperty, ITypedObject {
  Weight: number;
  Field: string;
  Access?: boolean;
  AccessCallback: any;
  Id: string;
}


/**
 * The base interface ViewMode Field, it could be based on column paradigm or not
 */
export interface IViewModeSortableField extends IViewModeField, ITypedObject {
  Sortable: boolean;
  DefaultSort: ViewSortDirection;
  DefaultUserConfigurationVisibility: FieldUserVisibilty;
}


/**
 * Modo en el cual el campo es añadido como columna a un listado (visible, oculto...)
 */
export enum FieldUserVisibilty {
  Visible = 0,
  Available = 1,
  Hidden = 2
}


/**
 * The lower class for any ViewMode Field, it could be based on column paradigm or not
 */
export class ViewModeField implements IViewModeField {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeField';
  readonly $type: string = ViewModeField.$type;
  Id: string;
  Weight: number;
  Field: string;
  Access?: boolean;
  AccessCallback: any;
  CssClasses: string[];
}


/**
 * The class for any ViewMode Field that can be sorted.
 */
export class ViewModeSortableField extends ViewModeField implements IViewModeSortableField {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeSortableField';
  readonly $type: string = ViewModeSortableField.$type;
  Sortable: boolean;
  DefaultSort: ViewSortDirection;
  DefaultUserConfigurationVisibility: FieldUserVisibilty;
}


/**
 * Types of view modes
 */
export enum ViewModeTypeEnum {
  Table = 0,
  CardBoard = 1,
  Calendar = 3
}


/**
 */
export interface IViewMode extends IViewProcessor, ICssClassesProperty, ITypedObject {
  Type: ViewModeTypeEnum;
}


/**
 * Abstract class form View Modes based on Columns
 */
export interface IViewModeColumnBased extends IViewMode, ITypedObject {
  Columns: { [id: string]: IViewModeSortableField };
  DefaultSingleFieldSort: ViewModeColumnBasedFieldSort;
}


/**
 * Display configuration for a Table/Grid display of a View
 */
export class ViewModeTable implements IViewModeColumnBased {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeTable.ViewModeTable';
  readonly $type: string = ViewModeTable.$type;
  Type: ViewModeTypeEnum;
  CssClasses: string[];
  Columns: { [id: string]: IViewModeSortableField };
  DefaultSingleFieldSort: ViewModeColumnBasedFieldSort;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Vincular campos a columnas
 */
export class ViewModeTableColumn extends ViewModeSortableField implements IViewModeSortableField {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeTable.ViewModeTableColumn';
  readonly $type: string = ViewModeTableColumn.$type;
  Header: string;
  HideEmptyColumn: boolean;
}


/**
 * Display configuration for a Table/Grid display of a View
 */
export class ViewModeCalendar implements IViewModeColumnBased {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeCalendar.ViewModeCalendar';
  readonly $type: string = ViewModeCalendar.$type;
  AvailableCalendarModes: { [id: string]: ICalendarMode };
  EventDefinition: EventDefinitionMap;
  DefaultCalendarMode?: any;
  Type: ViewModeTypeEnum;
  CssClasses: string[];
  Columns: { [id: string]: IViewModeSortableField };
  DefaultSingleFieldSort: ViewModeColumnBasedFieldSort;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Vincular campos a columnas
 */
export class ViewModeCalendarField extends ViewModeSortableField implements IViewModeSortableField {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeCalendar.ViewModeCalendarField';
  readonly $type: string = ViewModeCalendarField.$type;
}


/**
 */
export enum AvailableCalendarModesEnum {
  Month = 0,
  Week = 1,
  Day = 2
}


/**
 * Event Map information. The calendar needs the information about the dto or entity in order to show the data.
 */
export class EventDefinitionMap implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeCalendar.Dto.EventDefinitionMap';
  readonly $type: string = EventDefinitionMap.$type;
  TitleField: string[];
  InitialDateField: string;
  FinalDateField: string;
  AllDayField: string;
  BorderColorField: string;
  BackgroundColorField: string;
  TimeZone: string;
}


/**
 */
export interface ICalendarMode extends ITypedObject {
  Name: string;
  Icon: string;
  Id: string;
}


/**
 * Calendar Options: Week
 */
export class CalendarModeWeek implements ICalendarMode {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeCalendar.Dto.CalendarModes.CalendarModeWeek';
  readonly $type: string = CalendarModeWeek.$type;
  WeekStartsOn: DayOfWeek;
  WeekendDays: DayOfWeek[];
  StartTime: any;
  EndTime: any;
  Id: string;
  Name: string;
  Icon: string;
}


/**
 */
export class CalendarModeMonth implements ICalendarMode {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeCalendar.Dto.CalendarModes.CalendarModeMonth';
  readonly $type: string = CalendarModeMonth.$type;
  AvailableMonths: string[];
  WeekStartsOn: DayOfWeek;
  WeekendDays: DayOfWeek[];
  Id: string;
  Name: string;
  Icon: string;
}


/**
 */
export class CalendarModeDay implements ICalendarMode {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeCalendar.Dto.CalendarModes.CalendarModeDay';
  readonly $type: string = CalendarModeDay.$type;
  StartTime: any;
  EndTime: any;
  Id: string;
  Name: string;
  Icon: string;
}


/**
 * Display configuration for a Table/Grid display of a View
 */
export class ViewModeCardBoards implements IViewModeColumnBased {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeCardBoards.ViewModeCardBoards';
  readonly $type: string = ViewModeCardBoards.$type;
  ShowHeaderTitles: boolean;
  Type: ViewModeTypeEnum;
  CssClasses: string[];
  Columns: { [id: string]: IViewModeSortableField };
  DefaultSingleFieldSort: ViewModeColumnBasedFieldSort;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Vincular campos a columnas
 */
export class ViewModeCardBoardsField extends ViewModeTableColumn implements IViewModeSortableField {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewMode.ViewModeCardBoards.ViewModeCardBoardsField';
  readonly $type: string = ViewModeCardBoardsField.$type;
  Position: CardBoardFieldPosition;
}


/**
 * Field positions in Card View Mode
 */
export enum CardBoardFieldPosition {
  Body = 0,
  Header = 1,
  Footer = 2
}


/**
 * The ViewHeader
 */
export interface IViewHeader extends ITypedObject {
}


/**
 */
export class ViewHeaderMaterial implements IViewHeader {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewHeader.ViewHeaderMaterial';
  readonly $type: string = ViewHeaderMaterial.$type;
}


/**
 */
export class ViewHeaderSmall implements IViewHeader {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewHeader.ViewHeaderSmall';
  readonly $type: string = ViewHeaderSmall.$type;
}


/**
 */
export class ViewHeaderDefault implements IViewHeader {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewHeader.ViewHeaderDefault';
  readonly $type: string = ViewHeaderDefault.$type;
}


/**
 */
export class ViewHeaderNone implements IViewHeader {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ViewHeader.ViewHeaderNone';
  readonly $type: string = ViewHeaderNone.$type;
}


/**
 * Classes with a description
 */
export interface IDescriptionProperty extends ITypedObject {
  Description: string;
}


/**
 * Interface for single item operations.  Single item operations are actions in the list that happen on a SINGLE item, such as navigating to the edit page or opening a form to delete the item.
 */
export interface IViewsSingleItemOperation extends IDescriptionProperty, ITitleProperty, ICssClassesProperty, IAccessProperty, IWeightProperty, ITypedObject {
  Type: string;
  VisibilitySelector: any;
  AccessCallback: any;
  OnClickCommands: { [id: string]: ICommand };
  Disabled: boolean;
  Tooltip: string;
  Id: string;
}


/**
 * Represents an operation that links to another URL  The URL is precalculated in the backend.
 */
export class ViewsSingleItemOperationVboLoop implements IViewsSingleItemOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.ViewsSingleItemOperationVboLoop';
  readonly $type: string = ViewsSingleItemOperationVboLoop.$type;
  Type: string;
  VboTarget: string;
  Id: string;
  Title: string;
  Description: string;
  Access?: boolean;
  AccessCallback: any;
  CssClasses: string[];
  VisibilitySelector: any;
  OnClickCommands: { [id: string]: ICommand };
  Disabled: boolean;
  Tooltip: string;
  Weight: number;
}


/**
 * Types of single item operations.  Frontend support for this operations should be added
 */
export class SingleItemOperationTypeEnum implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.SingleItemOperationTypeEnum';
  public static readonly Handler: string = 'handler';
  public static readonly Form: string = 'form';
  public static readonly Link: string = 'link';
  public static readonly Legacy: string = 'legacy';
  public static readonly VboLoop: string = 'vboloop';
  public static readonly Commands: string = 'commands';
  public static readonly RowHandler: string = 'rowhandler';
  readonly $type: string = SingleItemOperationTypeEnum.$type;
}


/**
 * Interface for a field
 */
export interface IViewField extends IViewProcessor, ITypedObject {
  Type: ViewFieldTypeEnum;
  Label: string;
  Description: string;
  UserVisibility: FieldUserVisibilty;
  CssClasses: string[];
  PostProcessCallback: any;
  ToolTip: string;
}


/**
 * Types of view fields
 */
export enum ViewFieldTypeEnum {
  Data = 0,
  FixedValue = 1,
  SingleItemOperations = 2,
  ExpressionNative = 3,
  ExpressionText = 4,
  BulkOperations = 5,
  Extend = 6,
  DataDate = 7,
  RowOperations = 8
}


/**
 * Basic Field class for Lists
 */
export class ViewFieldBase implements IViewField, ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Field.ViewFieldBase';
  readonly $type: string = ViewFieldBase.$type;
  FieldFormatters: { [id: string]: any };
  Type: ViewFieldTypeEnum;
  Label: string;
  Description: string;
  UserVisibility: FieldUserVisibilty;
  CssClasses: string[];
  AddToMetadata: boolean;
  ToolTip: string;
  AccessCallback: any;
  PostProcessCallback: any;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * A field that holds a single item operations configuration
 */
export class ViewFieldSingleItemOperations extends ViewFieldBase implements IViewField, ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.ViewFieldSingleItemOperations';
  readonly $type: string = ViewFieldSingleItemOperations.$type;
  Type: ViewFieldTypeEnum;
  SingleItemOperations: { [id: string]: IViewsSingleItemOperation };
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * The result cell
 */
export interface IViewResultCell extends ICssClassesProperty, ITypedObject {
  Type: string;
  RawValue: any;
  Id: string;
}


/**
 * Base configuration that all cells should honor
 */
export class ViewResultCellBase implements IViewResultCell {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultCellBase';
  readonly $type: string = ViewResultCellBase.$type;
  Type: string;
  Id: string;
  CssClasses: string[];
  RawValue: any;
}


/**
 * Result cell for single item operations
 */
export class ViewResultCellSingleItemOperation extends ViewResultCellBase implements IViewResultCell {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.ViewResultCellSingleItemOperation';
  readonly $type: string = ViewResultCellSingleItemOperation.$type;
  Type: string;
  SingleItemOperations: { [id: string]: IViewsSingleItemOperation };
}


/**
 * Redirects the frontend to a FORM that takes as arguments the row input and metadata.
 */
export class ViewsSingleItemOperationForm implements IViewsSingleItemOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.ViewsSingleItemOperationForm';
  readonly $type: string = ViewsSingleItemOperationForm.$type;
  Type: string;
  FormId: string;
  FormArguments: { [id: string]: any };
  FormArgumentsFromExpression: { [id: string]: any };
  FormArgumentsFromViewColumns: { [id: string]: string };
  FormArgumentsFromViewMetadata: { [id: string]: string };
  ModalSettings: DtoFrontendModal;
  OnClickCommands: { [id: string]: ICommand };
  Id: string;
  Title: string;
  Description: string;
  Access?: boolean;
  AccessCallback: any;
  CssClasses: string[];
  VisibilitySelector: any;
  Disabled: boolean;
  Tooltip: string;
  Weight: number;
}


/**
 * Represents an empty button so that frontend can add custom handlers
 */
export class ViewsSingleItemOperationHandler implements IViewsSingleItemOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.ViewsSingleItemOperationHandler';
  readonly $type: string = ViewsSingleItemOperationHandler.$type;
  Type: string;
  Id: string;
  Title: string;
  Description: string;
  Access?: boolean;
  AccessCallback: any;
  CssClasses: string[];
  VisibilitySelector: any;
  OnClickCommands: { [id: string]: ICommand };
  Disabled: boolean;
  Tooltip: string;
  Weight: number;
}


/**
 * Keys for single item operations
 */
export class ViewsSingleItemOperationImplementationTypes implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.ViewsSingleItemOperationImplementationTypes';
  public static readonly Add: string = 'add';
  public static readonly Edit: string = 'edit';
  public static readonly View: string = 'view';
  public static readonly Remove: string = 'remove';
  public static readonly Recover: string = 'recover';
  public static readonly Copy: string = 'copy';
  public static readonly Access: string = 'access';
  public static readonly Dissasociate: string = 'dissasociate';
  public static readonly Details: string = 'details';
  public static readonly Execute: string = 'execute';
  public static readonly Resume: string = 'resume';
  public static readonly Pause: string = 'pause';
  public static readonly Stop: string = 'stop';
  public static readonly Reuse: string = 'reuse';
  public static readonly EditDates: string = 'editdates';
  public static readonly Historical: string = 'historical';
  public static readonly Download: string = 'download';
  public static readonly Assign: string = 'assign';
  public static readonly GenerateTemplate: string = 'generatetemplate';
  public static readonly Approve: string = 'approve';
  public static readonly Refuse: string = 'refuse';
  public static readonly SeeCompany: string = 'seecompany';
  public static readonly SeeProductiveUnit: string = 'seeproductiveunit';
  public static readonly Evaluate: string = 'evaluate';
  public static readonly Reevaluate: string = 'reevaluate';
  public static readonly Discard: string = 'discard';
  public static readonly Expire: string = 'expire';
  public static readonly AttachFile: string = 'attachfile';
  readonly $type: string = ViewsSingleItemOperationImplementationTypes.$type;
}


/**
 * Represents an operation that links to another URL  The URL is precalculated in the backend.
 */
export class ViewsSingleItemOperationLegacy implements IViewsSingleItemOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.ViewsSingleItemOperationLegacy';
  readonly $type: string = ViewsSingleItemOperationLegacy.$type;
  Type: string;
  Id: string;
  Title: string;
  Description: string;
  Access?: boolean;
  AccessCallback: any;
  CssClasses: string[];
  VisibilitySelector: any;
  OnClickCommands: { [id: string]: ICommand };
  Disabled: boolean;
  Tooltip: string;
  Weight: number;
}


/**
 * Represents an operation that links to another URL  The URL is precalculated in the backend.
 */
export class ViewsSingleItemOperationLink implements IViewsSingleItemOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.SingleItemOperations.ViewsSingleItemOperationLink';
  readonly $type: string = ViewsSingleItemOperationLink.$type;
  Type: string;
  Target: string;
  Id: string;
  Title: string;
  Description: string;
  Access?: boolean;
  AccessCallback: any;
  CssClasses: string[];
  VisibilitySelector: any;
  OnClickCommands: { [id: string]: ICommand };
  Disabled: boolean;
  Tooltip: string;
  Weight: number;
}


/**
 * Classes with a tooltip
 */
export interface ITooltipProperty extends ITypedObject {
  Tooltip: string;
}


/**
 * Interface for row operations.  Row operations are actions in the list that happen on a single row.
 */
export interface IViewRowOperations extends ITooltipProperty, IAccessProperty, ITypedObject {
  Type: string;
  AccessCallback: any;
  OnClickCommands: { [id: string]: IViewRowOperationsCommand };
  Id: string;
}


/**
 * Base class for row operations
 */
export class ViewRowOperationsBase implements IViewRowOperations {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.RowOperations.ViewRowOperationsBase';
  readonly $type: string = ViewRowOperationsBase.$type;
  Id: string;
  Tooltip: string;
  Type: string;
  Access?: boolean;
  AccessCallback: any;
  OnClickCommands: { [id: string]: IViewRowOperationsCommand };
}


/**
 */
export class ViewsRowOperationsTypes implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.RowOperations.ViewsRowOperationsTypes';
  public static readonly Select: string = 'select';
  readonly $type: string = ViewsRowOperationsTypes.$type;
}


/**
 * A field that holds a row operations configuration
 */
export class ViewFieldRowOperationsHandler extends ViewFieldBase implements IViewField, ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.RowOperations.ViewFieldRowOperationsHandler';
  readonly $type: string = ViewFieldRowOperationsHandler.$type;
  Weight: number;
  RowOperations: { [id: string]: IViewRowOperations };
  Type: ViewFieldTypeEnum;
  Id: string;
  Access?: boolean;
}


/**
 * Result cell for row operations
 */
export class ViewResultCellRowOperationsHandler extends ViewResultCellBase implements IViewResultCell {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.RowOperations.ViewResultCellRowOperationsHandler';
  readonly $type: string = ViewResultCellRowOperationsHandler.$type;
  Type: string;
  RowOperations: { [id: string]: IViewRowOperations };
}


/**
 * Command for row operations, includes the result of row
 */
export interface IViewRowOperationsCommand extends ICommand, ITypedObject {
  Result: ViewResultRow;
}


/**
 * RefreshInterval processor. Refresh the view every interval.
 */
export class ViewRefreshInterval implements IViewProcessor {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.RefreshInterval.ViewRefreshInterval';
  readonly $type: string = ViewRefreshInterval.$type;
  Weight: number;
  Interval?: number;
  Id: string;
  Access?: boolean;
}


/**
 */
export class ViewResultCellHtml extends ViewResultCellBase implements IViewResultCell {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultCellHtml';
  readonly $type: string = ViewResultCellHtml.$type;
  Type: string;
}


/**
 */
export interface IViewResutRowGroupHeaderAction extends ITypedObject {
  CssClasses: string[];
  Label: string;
  Tooltip: string;
  Access?: boolean;
  Disabled: boolean;
  OnClickCommands: { [id: string]: ICommand };
  AccessCallback: any;
  Id: string;
}


/**
 */
export class ViewResultRowGroupHeaderAction implements IViewResutRowGroupHeaderAction {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultRowGroupHeaderAction';
  readonly $type: string = ViewResultRowGroupHeaderAction.$type;
  Id: string;
  CssClasses: string[];
  Label: string;
  Tooltip: string;
  Disabled: boolean;
  Access?: boolean;
  OnClickCommands: { [id: string]: ICommand };
  AccessCallback: any;
}


/**
 * Celda con un enlace de navegación
 */
export class ViewResultNavigationNodeLink extends ViewResultCellBase implements IViewResultCell {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultNavigationNodeLink';
  readonly $type: string = ViewResultNavigationNodeLink.$type;
  Type: string;
  Controller: string;
  NavigateWithReturnToSelf: boolean;
  QueryArguments: { [id: string]: string };
  DefaultArguments: { [id: string]: string };
}


/**
 */
export class ViewResultFileLink extends ViewResultCellBase implements IViewResultCell {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultFileLink';
  readonly $type: string = ViewResultFileLink.$type;
  Type: string;
  Url: string;
}


/**
 * Cuando agrupamos en las vistas en modo expand, representa los atributos de un grupo concreto.
 */
export class ViewResultRowGroup implements ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultRowGroup';
  readonly $type: string = ViewResultRowGroup.$type;
  Id: string;
  CssClasses: string[];
  AggregateFields: { [id: string]: any };
  GroupHtmlSummary: string;
  EmptyGroup: boolean;
  EmptyGroupText: string;
  Operations: { [id: string]: IViewResutRowGroupHeaderAction };
}


/**
 * The result of executing a List
 */
export interface IViewResult extends ITypedObject {
  Results: ViewResultRow[];
  ResultCount: ViewExecutableCountResult;
  LastPage: boolean;
}


/**
 * Results after executing a list.
 */
export class ViewResult implements IViewResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResult';
  readonly $type: string = ViewResult.$type;
  Groups: { [id: string]: ViewResultRowGroup };
  Results: ViewResultRow[];
  ResultCount: ViewExecutableCountResult;
  LastPage: boolean;
}


/**
 */
export class ViewResultCellSimple extends ViewResultCellBase implements IViewResultCell {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultCellSimple';
  readonly $type: string = ViewResultCellSimple.$type;
  Type: string;
  ShowMoreTruncateLength?: number;
}


/**
 * Tipos de celda de resutlados en frontend
 */
export class ViewResultCellType implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultCellType';
  public static readonly Simple: string = 'simple';
  public static readonly SingleItemOperation: string = 'single-item-operations';
  public static readonly BulkOperation: string = 'bulk-operations';
  public static readonly FileLink: string = 'file-link';
  public static readonly NavigationNodeLink: string = 'navigation-node-link';
  public static readonly RowOperationsHandler: string = 'row-operations';
  public static readonly Html: string = 'html';
  readonly $type: string = ViewResultCellType.$type;
}


/**
 * Represents a data row for a list execution result.  This includes column information for every row, and optional metadata...
 */
export class ViewResultRow implements ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Result.ViewResultRow';
  readonly $type: string = ViewResultRow.$type;
  Columns: { [id: string]: IViewResultCell };
  CssClasses: string[];
  Key: string;
  GroupId: string;
  Metadata: { [id: string]: IViewsMetadata };
  Index: number;
}


/**
 */
export enum ViewsPagerLoadCountModeEnum {
  Always = 0,
  OnDemand = 1
}


/**
 * Interface for pagers
 */
export interface IViewsPager extends IViewProcessor, ITypedObject {
  Type: ViewsPagerTypeEnum;
}


/**
 * Types of pager
 */
export enum ViewsPagerTypeEnum {
  None = 0,
  Complete = 1,
  LoadMore = 2,
  Simple = 3,
  SpreadSheetExport = 4,
  MaterialComplete = 5,
  CompleteLazy = 6
}


/**
 * All pagers share this common behaviour
 */
export class ViewsPagerBase implements IViewsPager {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Pagers.ViewsPagerBase';
  readonly $type: string = ViewsPagerBase.$type;
  DefaultMaxPageSize: number;
  Type: ViewsPagerTypeEnum;
  HidePagerIfNotNeeded: boolean;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Complete pager that handles count() in a more efficient UX way.
 */
export class ViewsPagerCompleteLazy extends ViewsPagerBase implements IViewsPager {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Pagers.ViewsPagerCompleteLazy';
  readonly $type: string = ViewsPagerCompleteLazy.$type;
  Type: ViewsPagerTypeEnum;
  InitialPageSize: number;
  LoadCountMode: ViewsPagerLoadCountModeEnum;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 */
export class ViewsPagerMaterial extends ViewsPagerBase implements IViewsPager {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Pagers.ViewsPagerMaterial';
  readonly $type: string = ViewsPagerMaterial.$type;
  Type: ViewsPagerTypeEnum;
  AvailablePageSizes: number[];
  LoadCountMode: ViewsPagerLoadCountModeEnum;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * A pager... that does no paging
 */
export class ViewsPagerComplete extends ViewsPagerBase implements IViewsPager {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Pagers.ViewsPagerComplete';
  readonly $type: string = ViewsPagerComplete.$type;
  Type: ViewsPagerTypeEnum;
  AvailablePageSizes: number[];
  LoadCountMode: ViewsPagerLoadCountModeEnum;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * A pager... that does no paging
 */
export class ViewsPagerSpreadSheetExport extends ViewsPagerComplete implements IViewsPager {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Pagers.ViewsPagerSpreadSheetExport';
  public static readonly MaxPageSize: string = '1000';
  readonly $type: string = ViewsPagerSpreadSheetExport.$type;
  Type: ViewsPagerTypeEnum;
  DefaultMaxPageSize: number;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Como el paginador completo, pero sin hacer el count total
 */
export class ViewsPagerSimple extends ViewsPagerBase implements IViewsPager {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Pagers.ViewsPagerSimple';
  readonly $type: string = ViewsPagerSimple.$type;
  Type: ViewsPagerTypeEnum;
  PageSize: number;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 */
export interface IViewsPagerUserConfiguration extends ITypedObject {
}


/**
 * The views pager
 */
export class ViewsPagerUserConfigurationSimple implements IViewsPagerUserConfiguration {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Pagers.ViewsPagerUserConfigurationSimple';
  readonly $type: string = ViewsPagerUserConfigurationSimple.$type;
  PageSize: number;
  CurrentPage: number;
  MaxPageSize?: number;
}


/**
 * The local action interface
 */
export interface IViewsLocalAction extends IDescriptionProperty, ITitleProperty, ICssClassesProperty, IViewProcessor, ITypedObject {
  Type: string;
  OnClickCommands: { [id: string]: ICommand };
}


/**
 * Simple local actions
 */
export class ViewsLocalActionSimple implements IViewsLocalAction {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.LocalAction.ViewsLocalActionSimple';
  readonly $type: string = ViewsLocalActionSimple.$type;
  Type: string;
  Title: string;
  Description: string;
  CssClasses: string[];
  AccessCallback: any;
  OnClickCommands: { [id: string]: ICommand };
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Keys for local actions.  IMPORTANTE: No tiene implicaciones funcionales. Si quieres usar un literal... hazlo.  Es solo para no tener magic strings entre back y front.
 */
export class ViewsLocalActionType implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.LocalAction.ViewsLocalActionType';
  public static readonly New: string = 'add';
  public static readonly PopUpBulkOperationExport: string = 'popup-bulk-operation-export';
  public static readonly PopUpBulkOperationImport: string = 'popup-bulk-operation-import';
  public static readonly PopUpSelect: string = 'select';
  public static readonly PopUpCreate: string = 'create';
  readonly $type: string = ViewsLocalActionType.$type;
}


/**
 * The manager for simple filters. Todo esto de los simples filters está MUY acoplado, se ha hecho así a posta para ir rápido.  La arquitectura base de listados estaría preparada para crecer e implementar algo más desacoplado.
 */
export class ViewFilterManagerExposed implements IViewProcessor {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ExposedFilter.ViewFilterManagerExposed';
  readonly $type: string = ViewFilterManagerExposed.$type;
  Filters: { [id: string]: any };
  Weight: number;
  Id: string;
  Access?: boolean;
}


/**
 * La configuración de usuario del filtro expuesto?
 */
export class ViewFilterManagerExposedUserConfiguration implements IViewsUserConfiguration {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.ExposedFilter.ViewFilterManagerExposedUserConfiguration';
  readonly $type: string = ViewFilterManagerExposedUserConfiguration.$type;
  Id: string;
  SignedFormState: string;
  FormSubmitData: FormSubmitData;
}


/**
 */
export enum UserConfigurationOrigin {
  DEFAULT = 0,
  STORAGE = 10,
  REQUEST = 20
}


/**
 */
export class StoredConfigurationsResult implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Dto.StoredConfigurationsResult';
  readonly $type: string = StoredConfigurationsResult.$type;
  StoredViewUserConfigurations: StoredViewUserConfigurationInfo[];
}


/**
 */
export class StoredViewUserConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Dto.StoredViewUserConfiguration';
  readonly $type: string = StoredViewUserConfiguration.$type;
  Id?: string;
  Name: string;
  Description: string;
  StorageKey: string;
  ViewId: string;
  ViewUserConfiguration: ViewUserConfiguration;
}


/**
 */
export class StoredViewUserConfigurationInfo implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Dto.StoredViewUserConfigurationInfo';
  readonly $type: string = StoredViewUserConfigurationInfo.$type;
  Id?: string;
  Name: string;
  Description: string;
  ViewId: string;
}


/**
 */
export class ViewsExecutionRequestOptions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Dto.ViewsExecutionRequestOptions';
  readonly $type: string = ViewsExecutionRequestOptions.$type;
  LoadViewConfiguration: boolean;
  SkipLoadSavedUserConfiguration: boolean;
}


/**
 * Request a view execution from frontend
 */
export class ViewsExecutionRequest implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Dto.ViewsExecutionRequest';
  readonly $type: string = ViewsExecutionRequest.$type;
  PluginRequest: ViewsPluginRequest;
  UserConfiguration: ViewUserConfiguration;
}


/**
 * Result of executing a view
 */
export class ViewsExecutionResponse implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Dto.ViewsExecutionResponse';
  readonly $type: string = ViewsExecutionResponse.$type;
  Result: ViewResult;
  UserConfiguration: ViewUserConfiguration;
  ViewConfiguration: ViewConfiguration;
  ViewUserConfigurationOrigin: UserConfigurationOrigin;
  ViewStorageIsAllowed: boolean;
}


/**
 * Dto used to request a view configuration
 */
export class ViewsPluginRequest implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Dto.ViewsPluginRequest';
  readonly $type: string = ViewsPluginRequest.$type;
  Id: string;
  Arguments: Object;
  Parameters: { [id: string]: IViewRequestParameter };
}


/**
 */
export class CoreViewsCommandBase implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Command.CoreViewsCommandBase';
  readonly $type: string = CoreViewsCommandBase.$type;
  ViewsIdRegex: string;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Comando para refrescar una vista (refrescar es volver a actualizar los datos, NO resetear la configuración de usuario). Internamente hay una opción para borrar la selección VBO.
 */
export class CoreViewsCommandRefreshView extends CoreViewsCommandBase implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Command.CoreViewsCommandRefreshView';
  readonly $type: string = CoreViewsCommandRefreshView.$type;
  ClearSelectedVboItems: boolean;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * IVboOperation
 */
export interface IVboOperation extends IDescriptionProperty, ITitleProperty, ITooltipProperty, ICssClassesProperty, ITypedObject {
  Access?: boolean;
  AccessCallback: any;
  SkipRefreshViewOnFinish?: boolean;
  HideDownloadableResult?: boolean;
  Id: string;
}


/**
 */
export class ViewOperationBase implements IVboOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ViewOperationBase';
  readonly $type: string = ViewOperationBase.$type;
  Description: string;
  Title: string;
  Tooltip: string;
  CssClasses: string[];
  CssClassIcon: string;
  Id: string;
  Access?: boolean;
  AccessCallback: any;
  SkipRefreshViewOnFinish?: boolean;
  HideDownloadableResult?: boolean;
}


/**
 */
export class ViewOperationCommand extends ViewOperationBase implements IVboOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ViewOperationCommand';
  readonly $type: string = ViewOperationCommand.$type;
  Command: ICommand;
  Description: string;
  Title: string;
  Tooltip: string;
  CssClasses: string[];
  Id: string;
}


/**
 * Items preseleccionados para un VBO
 */
export class ViewsVboPreconfiguratedItem implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ViewsVboPreconfiguratedItem';
  readonly $type: string = ViewsVboPreconfiguratedItem.$type;
  Key: CoreHashedKey;
  Selected: boolean;
  Disabled: boolean;
}


/**
 * Modo de selección del listado
 */
export enum ViewsFieldVboSelectionMode {
  Multiple = 0,
  SingleButton = 1,
  SingleRadio = 2
}


/**
 * Operaciones en bloque para los elementos del listado. Ahora únicamente soporta la selección múltiple.
 */
export class ViewsFieldVbo extends ViewFieldBase implements IViewField, ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ViewsFieldVbo';
  readonly $type: string = ViewsFieldVbo.$type;
  Type: ViewFieldTypeEnum;
  DisplayTextExpression: any;
  IdExpression: any;
  ItemSelectionMode: ViewsFieldVboSelectionMode;
  EnableSelectAllItemsFromAllPages: boolean;
  HideSelectedItemsReview: boolean;
  HideItemNoOperationAvailable: boolean;
  DefaultSelection: ViewsVboPreconfiguratedItem[];
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 */
export interface IViewRequestParameter extends ITypedObject {
}


/**
 * Model class
 */
export class ViewsVboDefaultStartupParameter implements IViewRequestParameter {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ViewsVboDefaultStartupParameter';
  readonly $type: string = ViewsVboDefaultStartupParameter.$type;
  FieldId: string;
  SelectionMode?: any;
  Identifiers: ViewsVboPreconfiguratedItem[];
}


/**
 * Use this to identify objects for hashing
 */
export interface ISignedObject extends ITypedObject {
  Signature: string;
  SaltType?: any;
}


/**
 * Representa un elemento seleccionado
 */
export class ViewsVboSelectedItem implements IViewsMetadata, ISignedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ViewsVboSelectedItem';
  readonly $type: string = ViewsVboSelectedItem.$type;
  DisplayText: string;
  Id: string;
  HashedKey: CoreHashedKey;
  Disabled: boolean;
  Selected: boolean;
  ResultRow: ViewResultRow;
  Signature: string;
  SaltType?: any;
}


/**
 */
export class ViewsVboUserConfiguration implements IViewsUserConfiguration {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ViewsVboUserConfiguration';
  readonly $type: string = ViewsVboUserConfiguration.$type;
  Id: string;
  AllItemsInAllPages: boolean;
  SelectedItems: { [id: string]: ViewsVboSelectedItem };
}


/**
 */
export class ViewsOperationWithForm extends ViewOperationBase implements IVboOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ExportOperation.ViewsOperationWithForm';
  readonly $type: string = ViewsOperationWithForm.$type;
  Description: string;
  Title: string;
  Tooltip: string;
  CssClasses: string[];
  Id: string;
  Form: string;
  FormArguments: { [id: string]: any };
  OnFinishCommands: { [id: string]: ICommand };
  ModalType: DtoFrontendModalType;
}


/**
 * The default operation for View Export
 */
export class VboExportOperation extends ViewsOperationWithForm implements IVboOperation {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.BulkOperations.ExportOperation.VboExportOperation';
  public static readonly OperationId: string = 'Export';
  readonly $type: string = VboExportOperation.$type;
  Id: string;
  Form: string;
  Title: string;
  Description: string;
  Tooltip: string;
  ExportFields: string[];
  ExportFieldsExclude: string[];
  ExportDefaultSortField: string;
  SkipRefreshViewOnFinish?: boolean;
  CssClasses: string[];
}


/**
 * Interface for GroupByProcessors
 */
export interface IViewGroupBy extends IViewProcessor, ITypedObject {
  ExpandGroupMode: QueryGroupByMode;
}


/**
 * Different ways of handling group
 */
export enum QueryGroupByMode {
  Default = 0,
  Expand = 1,
  OverrideProjections = 2
}


/**
 * Información sobre el comportamiento de agrupación en una vista
 */
export class ViewGroupBy implements IViewGroupBy {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Aggregates.ViewGroupBy';
  readonly $type: string = ViewGroupBy.$type;
  ExpandGroupMode: QueryGroupByMode;
  AggregateFields: { [id: string]: any };
  KeySelector: any;
  ValidRowCondition: any;
  Orderby: { [id: string]: any };
  ExpandGroupOrderBy: { [id: string]: ViewSortDirection };
  EmptyGroupMessage: string;
  GroupByExpressionDiscriminator: any;
  GroupByDiscriminatorCallback: any;
  GroupByRowGroupProcessorCallback: any;
  GroupByHeaderCollapsible: boolean;
  GroupByHeaderCollapsedDefault: boolean;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 */
export enum QueryExecutableCountResultType {
  None = 0,
  Exact = 1
}


/**
 * A query group by clause. There is no result selector because this is automatically calculated by the projections in the view
 */
export class QueryGroupBy implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Query.QueryGroupBy';
  public static readonly ValidRowGroupFieldName: string = '__valid_group_row';
  readonly $type: string = QueryGroupBy.$type;
  ExpandGroupMode: QueryGroupByMode;
  AggregateFields: { [id: string]: any };
  KeySelector: any;
  GroupByOrderBy: { [id: string]: any };
  ExpandGroupOrderBy: { [id: string]: ViewSortDirection };
  ValidRowSelector: any;
}


/**
 * Describes current pagination status.
 */
export class QueryPagination implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Query.QueryPagination';
  readonly $type: string = QueryPagination.$type;
  CurrentPage: number;
  PageSize: number;
}


/**
 * Extensión especial para el IViewField para soportar campos con fuentes de datos manipulables (sort/order/where)
 */
export interface IViewFieldData extends IViewField, ITypedObject {
}


/**
 * Represents a Field that grabs data from the LINQ source of the view
 */
export class ViewFieldData extends ViewFieldBase implements ICssClassesProperty, IViewFieldData {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Field.ViewFieldData';
  readonly $type: string = ViewFieldData.$type;
  Source: any;
  ExpressionSelector: any;
  GroupByExpressionDiscriminator: any;
  ShowMoreTruncateLength?: number;
  Type: ViewFieldTypeEnum;
  TypeConfiguration: IViewsFieldTypeConfig;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Configuration for a field's display options
 */
export class ViewFieldNavigationNodeLink extends ViewFieldData implements ICssClassesProperty, IViewFieldData {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Field.ViewFieldNavigationNodeLink';
  readonly $type: string = ViewFieldNavigationNodeLink.$type;
  Controller: string;
  DefaultArguments: { [id: string]: string };
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Represents a Field that grabs data from the LINQ source of the view
 */
export class ViewFieldDataDate extends ViewFieldData implements ICssClassesProperty, IViewFieldData {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Field.ViewFieldDataDate';
  readonly $type: string = ViewFieldDataDate.$type;
  TimeZone: string;
  AutoLoadTimeZonePolicy: TimeZoneConversionPolicy;
  Type: ViewFieldTypeEnum;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 */
export enum TimeZoneConversionPolicy {
  ApplySystemTimeZone = 0,
  ApplyPersonTimeZoneFirst = 1,
  None = 2
}


/**
 * Configuration for a field's display options
 */
export class ViewFieldFileLink extends ViewFieldData implements ICssClassesProperty, IViewFieldData {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Field.ViewFieldFileLink';
  readonly $type: string = ViewFieldFileLink.$type;
  Url: string;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Represents a field with fixed data
 */
export class ViewFieldFixedValue extends ViewFieldBase implements IViewField, ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Field.ViewFieldFixedValue';
  readonly $type: string = ViewFieldFixedValue.$type;
  Type: ViewFieldTypeEnum;
  Id: string;
  Weight: number;
  Access?: boolean;
}


/**
 * Metada generated the views fields
 */
export class ViewFieldMetadata implements IViewsMetadata {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Views.Field.ViewFieldMetadata';
  readonly $type: string = ViewFieldMetadata.$type;
  Id: string;
  RawValue: any;
  RenderedValue: any;
}


/**
 * Tipos de formato de fechas
 */
export enum ViewDatePrecisionTypeEnum {
  Date = 0,
  DateAndTime = 1,
  Time = 2,
  ShortTime = 3,
  Time24hFormat = 4,
  DateAndShortTime = 5
}


/**
 */
export interface ILayoutConfiguration extends ITypedObject {
}


/**
 * Info about the theme
 */
export interface IThemeInfo extends ITypedObject {
  CurrentThemeHash: string;
  CurrentLayoutType: string;
  Theme: ThemeDefinitionCompiled;
  DataSource: Object;
}


/**
 */
export class ThemeDefinitionTemplate implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.Dto.ThemeDefinitionTemplate';
  readonly $type: string = ThemeDefinitionTemplate.$type;
  ThemeTemplate: string;
  CssTheme: string;
  Name: string;
  Description: string;
  Layout: ILayout;
  LayoutConfiguration: ILayoutConfiguration;
  LoginDefinition: LoginDefinition;
  HomeDefinition: HomeDefinition;
  AssemblyStylePaths: any[];
  ChartsPalette: string[];
}


/**
 * Welcome Message and Title
 */
export class HomeDefinition implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.Dto.HomeDefinition';
  readonly $type: string = HomeDefinition.$type;
  Title: string;
  ShortTitle: string;
  Subtitle: string;
  FooterText: string;
  HomeFormPlugin: string;
}


/**
 */
export interface ILayout extends ITypedObject {
  Id: string;
  Name: string;
  Root: string;
}


/**
 * Represents an available theme.
 */
export class ThemeLayoutDefinition implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.Dto.ThemeLayoutDefinition';
  readonly $type: string = ThemeLayoutDefinition.$type;
  CurrentLayout: string;
  LayoutConfiguration: ILayoutConfiguration;
}


/**
 * Welcome Message and Title
 */
export class LoginDefinition implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.Dto.LoginDefinition';
  readonly $type: string = LoginDefinition.$type;
  Title: string;
  WelcomeMessage: string;
}


/**
 * Represents an available theme.
 */
export class ThemeDefinitionCompiled implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.Dto.ThemeDefinitionCompiled';
  readonly $type: string = ThemeDefinitionCompiled.$type;
  ThemeId: string;
  CssTheme: string;
  AssemblyStyles: string[];
  UserStyles: string[];
  ThemeTemplate: string;
  LeftMenu: string;
  Layout: ILayout;
  LayoutConfiguration: ILayoutConfiguration;
  LoginDefinition: LoginDefinition;
  HomeDefinition: HomeDefinition;
  ChartsPalette: string[];
}


/**
 */
export class FuseConfig implements ILayoutConfiguration {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultLayouts.FuseConfig';
  readonly $type: string = FuseConfig.$type;
  colorTheme: string;
  customScrollbars?: boolean;
  layout: LayoutSettings;
}


/**
 */
export class LayoutSettings implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultLayouts.LayoutSettings';
  readonly $type: string = LayoutSettings.$type;
  style: string;
  width: string;
  navbar: NavBarSettings;
  toolbar: ToolBarSettings;
  footer: ToolBarSettings;
  sidepanel: SidePanelSettings;
}


/**
 */
export class NavBarSettings implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultLayouts.NavBarSettings';
  readonly $type: string = NavBarSettings.$type;
  primaryBackground: string;
  secondaryBackground: string;
  hidden?: boolean;
  folded?: boolean;
  position: string;
  variant: string;
}


/**
 */
export class ToolBarSettings implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultLayouts.ToolBarSettings';
  readonly $type: string = ToolBarSettings.$type;
  customBackgroundColor?: boolean;
  background: string;
  hidden?: boolean;
  position: string;
}


/**
 */
export class SidePanelSettings implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultLayouts.SidePanelSettings';
  readonly $type: string = SidePanelSettings.$type;
  hidden?: boolean;
  position: string;
}


/**
 * Fall apart layout: The first PRIME layout
 */
export class FuseLayout implements ILayout {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultLayouts.FuseLayout';
  public static readonly LayoutId: string = 'FuseLayout';
  readonly $type: string = FuseLayout.$type;
  Id: string;
  Name: string;
  Root: string;
}


/**
 */
export class MainConfig implements ILayoutConfiguration {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultLayouts.MainConfig';
  readonly $type: string = MainConfig.$type;
  Hidden?: boolean;
}


/**
 * Fall apart layout: The first PRIME layout
 */
export class MainLayout implements ILayout {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultLayouts.MainLayout';
  public static readonly LayoutId: string = 'MainLayout';
  readonly $type: string = MainLayout.$type;
  Id: string;
  Name: string;
  Root: string;
}


/**
 * PrimeFuseTheme theme
 */
export class PrimeFuseTheme extends ThemeDefinitionTemplate implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeFuseTheme';
  readonly $type: string = PrimeFuseTheme.$type;
  ThemeTemplate: string;
  CssTheme: string;
  Name: string;
  Description: string;
  Layout: ILayout;
  LayoutConfiguration: ILayoutConfiguration;
  LoginDefinition: LoginDefinition;
  HomeDefinition: HomeDefinition;
  ChartsPalette: string[];
  AssemblyStylePaths: any[];
}


/**
 * PrimeFuseTheme theme
 */
export class PrimeFusePablICOTheme extends PrimeFuseTheme implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeFusePablICOTheme';
  readonly $type: string = PrimeFusePablICOTheme.$type;
  ThemeTemplate: string;
  Name: string;
  Description: string;
  AssemblyStylePaths: any[];
}


/**
 * PrimeFuseTheme theme
 */
export class PrimeFuseBlueTheme extends PrimeFuseTheme implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeFuseBlueTheme';
  readonly $type: string = PrimeFuseBlueTheme.$type;
  ThemeTemplate: string;
  Name: string;
  Description: string;
  AssemblyStylePaths: any[];
}


/**
 * PrimeFuseTheme theme
 */
export class PrimeFuseDarkRedTheme extends PrimeFuseTheme implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeFuseDarkRedTheme';
  readonly $type: string = PrimeFuseDarkRedTheme.$type;
  ThemeTemplate: string;
  Name: string;
  Description: string;
  AssemblyStylePaths: any[];
}


/**
 * PrimeFuseTheme theme
 */
export class PrimeFusePurplePink extends PrimeFuseTheme implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeFusePurplePink';
  readonly $type: string = PrimeFusePurplePink.$type;
  ThemeTemplate: string;
  Name: string;
  Description: string;
  AssemblyStylePaths: any[];
  ChartsPalette: string[];
}


/**
 * PrimeFuseTheme theme
 */
export class PrimeFuseSkyBlueTheme extends PrimeFuseTheme implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeFuseSkyBlueTheme';
  readonly $type: string = PrimeFuseSkyBlueTheme.$type;
  ThemeTemplate: string;
  Name: string;
  Description: string;
  AssemblyStylePaths: any[];
}


/**
 * PrimeFuseTheme theme
 */
export class PrimeFuseTurquoise extends PrimeFuseTheme implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeFuseTurquoise';
  readonly $type: string = PrimeFuseTurquoise.$type;
  ThemeTemplate: string;
  Name: string;
  Description: string;
  AssemblyStylePaths: any[];
  ChartsPalette: string[];
}


/**
 * PrimeFuseTheme theme
 */
export class PrimeFuseYellowTheme extends PrimeFuseTheme implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeFuseYellowTheme';
  readonly $type: string = PrimeFuseYellowTheme.$type;
  ThemeTemplate: string;
  Name: string;
  Description: string;
  AssemblyStylePaths: any[];
}


/**
 * PrimeVector theme
 */
export class PrimeBaseTheme extends ThemeDefinitionTemplate implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Theme.DefaultThemes.PrimeBaseTheme';
  readonly $type: string = PrimeBaseTheme.$type;
  ThemeTemplate: string;
  CssTheme: string;
  Name: string;
  Description: string;
  Layout: ILayout;
  LayoutConfiguration: ILayoutConfiguration;
}


/**
 */
export class CoreExecuteTemplateCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Templates.Command.CoreExecuteTemplateCommand';
  readonly $type: string = CoreExecuteTemplateCommand.$type;
  ExecuteCommands: { [id: string]: ICommand };
  OnFinishCommands: { [id: string]: ICommand };
  SeeProgress: boolean;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 */
export interface ICoreFrontendComponentMenuSettings extends ITypedObject {
  Type: string;
}


/**
 * Componente de frontend con la estructura FORMULARIO 1 LISTADO 1 FORMULARIO 2 FORMULARIO 3
 */
export class CoreGenericFormViewFormPageSimpleComponent implements ICoreFrontendComponentMenuSettings {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.PageNavigation.Dto.CoreGenericFormViewFormPageSimpleComponent';
  readonly $type: string = CoreGenericFormViewFormPageSimpleComponent.$type;
  Type: string;
  Form1Id: string;
  Form1ArgumentMapping: { [id: string]: string };
  Form2Id: string;
  Form2ArgumentMapping: { [id: string]: string };
  Form3Id: string;
  Form3ArgumentMapping: { [id: string]: string };
  List1Id: string;
  List1IdArgumentMapping: { [id: string]: string };
}


/**
 */
export class CoreGenericViewPageSimpleComponent implements ICoreFrontendComponentMenuSettings {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.PageNavigation.Dto.CoreGenericViewPageSimpleComponent';
  readonly $type: string = CoreGenericViewPageSimpleComponent.$type;
  Type: string;
  ViewsId: string;
  EnableBackButton: boolean;
  ArgumentMapping: { [id: string]: string };
}


/**
 */
export class CoreGenericFormPageSimpleComponent implements ICoreFrontendComponentMenuSettings {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.PageNavigation.Dto.CoreGenericFormPageSimpleComponent';
  readonly $type: string = CoreGenericFormPageSimpleComponent.$type;
  Type: string;
  FormId: string;
  FormArgumentMapping: { [id: string]: string };
  FormArgumentMappingFromResolver: { [id: string]: string };
}


/**
 * Nos permite generar parte del routing de angular.... desde backend.
 */
export class CoreMenuFrontendRoute implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.PageNavigation.Dto.CoreMenuFrontendRoute';
  readonly $type: string = CoreMenuFrontendRoute.$type;
  Path: string;
  Controller: string;
  Location: CoreMenuFrontendRouteLocation;
  Component: ICoreFrontendComponentMenuSettings;
}


/**
 */
export enum CoreMenuFrontendRouteLocation {
  Home = 0,
  Root = 1
}


/**
 * Message type. Do not use ENUMS to allow for maximum flexibility and extensibility in implementations.
 */
export class MessageType implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Message.MessageType';
  public static readonly STATUS: string = 'status';
  public static readonly WARNING: string = 'warning';
  public static readonly ERROR: string = 'error';
  public static readonly INFO: string = 'info';
  readonly $type: string = MessageType.$type;
}


/**
 * Command para lanzar mensaje en front (toast)
 */
export class CoreMessageCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Message.Command.CoreMessageCommand';
  readonly $type: string = CoreMessageCommand.$type;
  Message: MessageCommandData;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Data sent to client for the message command
 */
export class MessageCommandData implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Message.Command.MessageCommandData';
  readonly $type: string = MessageCommandData.$type;
  Title: string;
  Message: string;
  Type: string;
  EnableHtml: boolean;
}


/**
 * Append to the current controller and navigate to it
 */
export class CoreMenuNavigateToAppendedController implements ICommandWithArguments {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.CoreMenuNavigateToAppendedController';
  readonly $type: string = CoreMenuNavigateToAppendedController.$type;
  ToAppend: string;
  Arguments: { [id: string]: string };
  ArgumentValues: any[];
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Navegar en una dirección concreta
 */
export class CoreMenuNavigateDirectionCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.CoreMenuNavigateDirectionCommand';
  readonly $type: string = CoreMenuNavigateDirectionCommand.$type;
  Direction: CoreMenuNavigateDirectionEnum;
  SkipParametricNodeCount: number;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 */
export enum CoreMenuNavigateDirectionEnum {
  Left = 0,
  Right = 1,
  Up = 2
}


/**
 * Represents an already compiled menu item, we separate this from menu definitions themselves to avoid confusion.
 */
export class MenuItemCompiled implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.MenuItemCompiled';
  readonly $type: string = MenuItemCompiled.$type;
  Id: string;
  ExecutionArguments: { [id: string]: string };
  title: string;
  PageTitle: string;
  PageTitleTooltip: string;
  NavigationToolTip: string;
  description: string;
  path: string;
  controller: string;
  RedirectController: string;
  RedirectControllerArguments: { [id: string]: string };
  permissions: string[];
  weight: number;
  menuName: string;
  Hidden: boolean;
  Disabled: boolean;
  menuType: MenuType;
  arguments: string[];
  argumentValues: Object;
  DefaultArguments: { [id: string]: string };
  children: MenuItemCompiled[];
  ForceOnClickNavigation: boolean;
  depth: number;
  access?: boolean;
  CssClasses: string[];
  AccessBlockedCommands: { [id: string]: ICommand };
  Metadata: { [id: string]: any };
  SkipLoadChildren: boolean;
  OldControllers: string[];
  ControllerReplaced: boolean;
  OnClickCommands: { [id: string]: ICommand };
  ForceToShowBreadcrum: boolean;
  RevokeAccessIfNoChildren: boolean;
  Category: string;
}


/**
 * Types of menu items.
 */
export enum MenuType {
  Normal = 0,
  LocalAction = 1,
  Undefined = 2,
  Menu = 3,
  TabContainer = 4,
  Ghost = 5,
  Anchor = 6,
  WizardContainer = 7
}


/**
 * Dto for NavigationTree Frontend
 */
export class NavigationTreeResponse implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Dto.NavigationTreeResponse';
  readonly $type: string = NavigationTreeResponse.$type;
  NavigationTree: { [id: string]: MenuPathInfo };
  NavigationTreeAlterations: { [id: string]: MenuPathInfo };
  FirstGetMenuResponse: MenuQueryResponse;
  ReplacedControllers: { [id: string]: string };
}


/**
 * Información sobre un controlador
 */
export class MenuPathInfo implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Dto.MenuPathInfo';
  readonly $type: string = MenuPathInfo.$type;
  Id: string;
  Controller: string;
  MenuName: string;
  MenuType: MenuType;
  AnchorDefaultArguments: { [id: string]: string };
}


/**
 * Dto for menu querying from frontend
 */
export class MenuQueryResponse implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Dto.MenuQueryResponse';
  readonly $type: string = MenuQueryResponse.$type;
  Menus: MenuItemCompiled[];
  BeginsAtPath: string;
}


/**
 * Delete the navigation tree up to a certain level
 */
export class CoreMenuNavigationRefreshPartialCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Command.CoreMenuNavigationRefreshPartialCommand';
  readonly $type: string = CoreMenuNavigationRefreshPartialCommand.$type;
  Levels: number;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Refresh the navigation tree
 */
export class CoreMenuNavigateToUrl implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Command.CoreMenuNavigateToUrl';
  readonly $type: string = CoreMenuNavigateToUrl.$type;
  Url: string;
  IsExternal: boolean;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Navegar a un nodo concreto utilizando su ID. Cuidado porque es peligroso ya que en frontend navega a la última versión "vista" de ese nodo (un nodo paramétrico puede tener tantas versiones como variantes de sus parámetros).
 */
export class CoreMenuNavigateToIdentifierCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Command.CoreMenuNavigateToIdentifierCommand';
  readonly $type: string = CoreMenuNavigateToIdentifierCommand.$type;
  Identifier: string;
  ReturnToCurrentUri: boolean;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Este comando en realidad no hace nada, solo sirve para que - en una cadena de comandos - podamos asegurarnos de que si hay un refresco de de navegación en curso (por ejemplo al cambiar de contexto) podamos sincronizar los comandos para que se ejecuten después de obtener la nueva navegación.
 */
export class CoreMenuNavigateWaitCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Command.CoreMenuNavigateWaitCommand';
  readonly $type: string = CoreMenuNavigateWaitCommand.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Navegar a un nodo concreto
 */
export class CoreMenuNavigateToControllerCommand implements ICommandWithArguments {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Command.CoreMenuNavigateToControllerCommand';
  readonly $type: string = CoreMenuNavigateToControllerCommand.$type;
  Controller: string;
  Arguments: { [id: string]: string };
  QueryString: { [id: string]: string };
  ArgumentValues: any[];
  ReturnToCurrentUri: boolean;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Refresh the navigation tree
 */
export class CoreMenuNavigationRefreshCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Command.CoreMenuNavigationRefreshCommand';
  readonly $type: string = CoreMenuNavigationRefreshCommand.$type;
  Full: boolean;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Menus de las aplicaciones
 */
export class ApplicationMenu implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Menu.Enums.ApplicationMenu';
  public static readonly MenuDefault: string = 'default-menu';
  public static readonly MenuLeft: string = 'left-menu';
  public static readonly MenuUser: string = 'user-menu';
  public static readonly MenuToolbar: string = 'toolbar';
  readonly $type: string = ApplicationMenu.$type;
}


/**
 * Frontend error DTO
 */
export class FrontendError implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Log.Dto.FrontendError';
  readonly $type: string = FrontendError.$type;
  TicketId: string;
  Timestamp: Date;
  StackTrace: string;
  Path: string;
  Message: string;
  InfoPlatformBrowser: string;
}


/**
 */
export class CoreLocaleLanguageKeys implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Locale.CoreLocaleLanguageKeys';
  public static readonly NoTranslationLanguageCode: string = 'NOLANGUAGE';
  public static readonly TagsLanguageCode: string = 'tags';
  public static readonly MissingTargetValue: string = '𝌆';
  public static readonly SameAsSourceTargetValue: string = '§';
  readonly $type: string = CoreLocaleLanguageKeys.$type;
}


/**
 */
export enum LanguageTranslationPolicy {
  ApplySystemLanguage = 0,
  ApplyPersonLanguageFirst = 1,
  ApplyCompanyLanguageFirst = 2
}


/**
 * DTO que se usa exclusivamente para enviar las traducciones a frontend, donde el source ya está incluido dentro de la clave
 */
export class LocaleTarget implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Locale.Dto.LocaleTarget';
  readonly $type: string = LocaleTarget.$type;
  key: string;
  literal_translation: string;
  language_translation: string;
}


/**
 * AfterFormSubmitStatusEnum
 */
export enum FormStatusEnum {
  Untouched = 0,
  Touched = 1,
  Pristine = 2,
  Dirty = 3
}


/**
 * Represents a form load request
 */
export class FormPluginRequest implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormPluginRequest';
  readonly $type: string = FormPluginRequest.$type;
  FormId: string;
  Arguments: Object;
}


/**
 * Types of resize policy text area
 */
export enum FormTextAreaResizePolicy {
  All = 0,
  Vertical = 1,
  Horizontal = 2,
  None = 3
}


/**
 * Type of form messages
 */
export class FormValidationTypeEnum implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormValidationTypeEnum';
  public static readonly Required: string = 'required';
  public static readonly MaxLength: string = 'maxlength';
  public static readonly MinLength: string = 'minlength';
  public static readonly MaxValue: string = 'maxvalue';
  public static readonly MinValue: string = 'minvalue';
  public static readonly Pattern: string = 'pattern';
  public static readonly ConfirmField: string = 'confirmfield';
  public static readonly IsNumber: string = 'isnumber';
  public static readonly StepNumber: string = 'stepnumber';
  readonly $type: string = FormValidationTypeEnum.$type;
}


/**
 * Forma de propagación de ReadOnly a los hijos en FormElementFieldset.  Importante notar que  esto solo tiene implicaciones en el cálculo inicial de los estados enabled/disabled en backend y a qué elementos se aplica inicialmente. La herencia de enabled/disabled en frontend atiende únicamente a la que está establecida por defecto para HTML5:  * Si desactivo un fieldset, todos sus hijos se desactivan * Si activo un fieldset, se activan sus hijos, salvo que estén explícitamente deshabilitados
 */
export enum FormElementReadOnlyPropagationMode {
  OnlyChildren = 1,
  OnlySelf = 2,
  SelfAndChildren = 3,
  IgnoreParent = 4
}


/**
 * Representa un elemento de formulario de agrupación de controles (Fieldset/FormConfig)
 */
export interface IFormElementGroup extends ITypedObject {
  DisableBootstrapColumns?: boolean;
  DisplaySettings: FormElementDisplaySettings;
}


/**
 * Display settings...
 */
export enum FormElementDisplaySettings {
  None = 1,
  AutoNewRow = 2
}


/**
 * Este DTO controla un comportamiento que abre una modal de confirmación antes de modificar el valor de un campo de la API de formularios. Se puede también incorporar a los botones para solicitar confirmación antes de ejecutar los handlers del botón
 */
export class FormInputChangeAlert extends DtoFrontendModal implements ICssClassesProperty, ITitleProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormInputChangeAlert';
  readonly $type: string = FormInputChangeAlert.$type;
  NoLabel: string;
  YesLabel: string;
  HideNoButton: boolean;
  Message: string;
  Messages: string[];
}


/**
 */
export interface IFormElement extends ITypedObject {
  Type: FormElementType;
  Title: string;
  Tooltip: string;
  Description: string;
  ReadOnly: boolean;
  ClientId: string;
  ClientPath: string;
  Access?: boolean;
  Classes: string[];
  WidthColumn: number;
  OffsetWidthColumn: number;
  Data: any;
  ReadonlyInheritanceMode: FormElementReadOnlyPropagationMode;
  DisplaySettings: FormElementDisplaySettings;
  NewRow?: boolean;
  Order: number;
  Visible: boolean;
  Children: { [id: string]: FormElement };
}


/**
 * Form Element Type
 */
export enum FormElementType {
  Form = 0,
  FieldSet = 1,
  Checkbox = 2,
  Date = 3,
  File = 4,
  Password = 5,
  Radio = 6,
  Select = 7,
  SelectNative = 8,
  TextInput = 9,
  TextArea = 10,
  Number = 11,
  Email = 12,
  AutoComplete = 13,
  RichText = 14,
  Bool = 15,
  Percent = 16,
  Checkboxes = 17,
  Button = 18,
  Label = 19,
  Table = 20,
  Secret = 21,
  Map = 22,
  HtmlPlaceholder = 23,
  ViewsSelector = 24,
  ViewsEmbed = 25,
  DatePicker = 26,
  DateTimePicker = 27,
  TimePicker = 28,
  TagSelector = 29,
  FrontendCustom = 30,
  AutocompleteNew = 31,
  CheckboxesModal = 32,
  QRCode = 33,
  TimePickerTimeSpan = 34,
  MapInput = 35,
  EmbeddedDocument = 36,
  ChartsEmbed = 37,
  Compound = 38,
  Progress = 39,
  NavigationBoard = 40,
  SimpleMessage = 41,
  BatchTask = 42,
  Avatar = 43,
  SelectDynamic = 44,
}


/**
 */
export interface IFormElementInput extends IFormElement, ITypedObject {
  DefaultValue: any;
  Placeholder: string;
  Required: boolean;
  MaxLength: number;
  MinLength: number;
  Validators: { [id: string]: IFormElementInputValidator };
  AsyncValidation: boolean;
  DoNotPropagateValidationErrorsToFormSummary: boolean;
}


/**
 * Types of field sets
 */
export enum FormFieldsetType {
  Boxed = 0,
  Invisible = 1,
  Collapsible = 2
}


/**
 * Type of form submits
 */
export enum FormSubmitType {
  Normal = 1,
  Rebuild = 2,
  RebuildValues = 3
}


/**
 * Form Element Action
 */
export enum FormElementAction {
  Submit = 0,
  Cancel = 1,
  OtherAction = 2
}


/**
 * Errores de validación del formulario
 */
export class FormValidationError implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormValidationError';
  readonly $type: string = FormValidationError.$type;
  Element: string;
  Message: string;
  ValidationKey: string;
}


/**
 * Type of form messages
 */
export enum FormMessageTypeEnum {
  Error = 0
}


/**
 * Represents all client side generated input for the form submission process.
 */
export class FormSubmitData implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormSubmitData';
  readonly $type: string = FormSubmitData.$type;
  formInput: Object;
  submitElement: string;
  SubmitType: FormSubmitType;
}


/**
 * The basic representation for a field element (no input)
 */
export class FormElement implements IFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormElement';
  readonly $type: string = FormElement.$type;
  ElementStates: { [id: string]: IStateApiCondition };
  ElementActions: ActionsApiTrigger[];
  ReadonlyInheritanceMode: FormElementReadOnlyPropagationMode;
  Type: FormElementType;
  Title: string;
  Tooltip: string;
  ComponentClass: string;
  ComponentToolTipDisabled: string;
  Metadata: Object;
  Description: string;
  AccessCallback: any;
  EditCallback: any;
  ReadOnly: boolean;
  DisplayOnly: boolean;
  ClientId: string;
  Access?: boolean;
  StandAlone: boolean;
  Classes: string[];
  DataAttributes: { [id: string]: any };
  WidthColumn: number;
  OffsetWidthColumn: number;
  ClientPath: string;
  Data: any;
  DisplaySettings: FormElementDisplaySettings;
  DefaultWidthColumn?: number;
  NewRow?: boolean;
  ComponentNewRow?: boolean;
  Order: number;
  Visible: boolean;
  Children: { [id: string]: FormElement };
  Handlers: any[];
  Item: FormElement;
}


/**
 * A form configuration.
 */
export class FormConfiguration extends FormElement implements IFormElement, IFormElementGroup {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormConfiguration';
  readonly $type: string = FormConfiguration.$type;
  Type: FormElementType;
  ReadonlyInheritanceMode: FormElementReadOnlyPropagationMode;
  DefaultAction: string;
  DefaultFocusElementPriority: string[];
  DisableBootstrapColumns?: boolean;
  EnableElementValueNesting?: boolean;
  Tags: string[];
  AllowAnonymousExecution: boolean;
  DisableAutomaticElementFocus?: boolean;
  DoNotAllowLeavingFormIfDirty: boolean;
}


/**
 * Represents a form state.
 */
export interface IFormState extends ITypedObject {
  FormId: string;
  Arguments: Object;
  SubmitElement: string;
  SubmitType: FormSubmitType;
  Form: FormConfiguration;
  RawValues: Object;
  Values: Object;
  SubmitResult: FormSubmitResult;
  ValidationErrors: FormValidationError[];
  Data: { [id: string]: any };
  RebuildNewWindowModalSettings: DtoFrontendModal;
}


/**
 * Represents the state of a form. Form state prevents a form from the need of being rebuilt when being submitted, at the cost of having to store a full copy of it. It also provides means for data persistance.  Initial implementation sends the full form state to the client, and the client must send it back.  The form state is criptographically signed, so data in it cannot be tampered.
 */
export class FormState implements IFormState {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormState';
  readonly $type: string = FormState.$type;
  Data: { [id: string]: any };
  Values: Object;
  SubmitType: FormSubmitType;
  SubmitElement: string;
  Arguments: Object;
  Form: FormConfiguration;
  FormId: string;
  RawValues: Object;
  SubmitResult: FormSubmitResult;
  ValidationErrors: FormValidationError[];
  RebuildNewWindowModalSettings: DtoFrontendModal;
}


/**
 * Result of submitting a form.
 */
export class FormSubmitResult implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormSubmitResult';
  readonly $type: string = FormSubmitResult.$type;
  Status: string;
  Actions: { [id: string]: ICommand };
  Result: any;
}


/**
 * Form Submit Status Type
 */
export class FormSubmitStatusType implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormSubmitStatusType';
  public static readonly Success: string = 'success';
  public static readonly Reset: string = 'reset';
  public static readonly Error: string = 'error';
  public static readonly Rebuild: string = 'rebuild';
  public static readonly RebuildValues: string = 'rebuild_values';
  public static readonly RebuildNewWindow: string = 'rebuild_new_window';
  public static readonly RebuildCloseWindow: string = 'rebuild_close_window';
  public static readonly ResetCloseWindow: string = 'reset_close_window';
  readonly $type: string = FormSubmitStatusType.$type;
}


/**
 */
export interface IStateApiCondition extends ITypedObject {
  Negate: boolean;
  Type: StateApiConditionType;
}


/**
 * Types of conditions for IStateApiCondition
 */
export enum StateApiConditionType {
  GroupAnd = 0,
  GroupOr = 1,
  GroupXor = 2,
  Trigger = 3
}


/**
 */
export class StateApiGroupCondition implements IStateApiCondition {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.StatesApi.StateApiGroupCondition';
  readonly $type: string = StateApiGroupCondition.$type;
  Negate: boolean;
  Type: StateApiConditionType;
  Conditions: IStateApiCondition[];
}


/**
 */
export class StateApiTriggerCondition implements IStateApiCondition {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.StatesApi.StateApiTriggerCondition';
  readonly $type: string = StateApiTriggerCondition.$type;
  Type: StateApiConditionType;
  Selector: string;
  Trigger: FormElementTrigger;
  Value: any;
  Value2: any;
  Negate: boolean;
}


/**
 * The available triggers for the state API.
 */
export enum FormElementTrigger {
  Empty = 0,
  Checked = 1,
  Expanded = 2,
  Value = 3,
  AnyValue = 4,
  ValueSmallerThan = 5,
  ValueGreaterThan = 6,
  ValueInRange = 7,
  Passthrough = 8,
  Modified = 9,
  HasOptions = 10
}


/**
 * The available trigger conditions for the State API
 */
export enum FormElementState {
  Enabled = 0,
  Disabled = 1,
  Required = 2,
  Optional = 3,
  Visible = 4,
  Invisible = 5,
  Checked = 6,
  Unchecked = 7,
  Expanded = 8,
  Collapsed = 9
}


/**
 */
export class ActionsApiAction implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.ActionsApi.ActionsApiAction';
  readonly $type: string = ActionsApiAction.$type;
  TargetElement: string;
  Action: FormElementActionsApiAction;
  Data: any;
}


/**
 * Trigger types
 */
export enum FormElementActionsApiAction {
  ClearValues = 0,
  Reset = 1,
  Rebuild = 2,
  RebuildValues = 3,
  SetValue = 4,
  ConfirmMessage = 5,
  RunCustomCallback = 6,
  Collapse = 7
}


/**
 * Triggers  de la API de acciones
 */
export class ActionsApiTrigger implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.ActionsApi.ActionsApiTrigger';
  readonly $type: string = ActionsApiTrigger.$type;
  TriggerType: FormElementActionsApiTrigger;
  TriggerData: any;
  TriggerData2: any;
  Actions: ActionsApiAction[];
}


/**
 * Trigger types
 */
export enum FormElementActionsApiTrigger {
  ValueChanged = 0,
  ValueChangedTo = 1,
  ValueChangedFrom = 2,
  ValueChangedFromTo = 3,
  ValueFilled = 4,
  ValueEmptied = 5
}


/**
 */
export interface IFormElementInputValidator extends ITypedObject {
  Id: string;
}


/**
 * Validador de expresión regular
 */
export class FormElementInputRegexValidator implements IFormElementInputValidator {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.FormElementInputValidators.FormElementInputRegexValidator';
  readonly $type: string = FormElementInputRegexValidator.$type;
  Id: string;
  RegexValidation: string;
  RegexValidationMessage: string;
}


/**
 * Interface for Callback Result
 */
export interface ICallbackResult extends ITypedObject {
}


/**
 * Normalized result for a FormElementAutocompleteCallback
 */
export class FormElementInputAsyncValueCallbackResult implements ICallbackResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementInputAsyncValueCallbackResult';
  readonly $type: string = FormElementInputAsyncValueCallbackResult.$type;
  Value: any;
}


/**
 */
export interface IFormElementExecuteElementHandlerRequest extends ITypedObject {
  FieldSelector: string;
  HandlerType: FormHandlerType;
}


/**
 * Handler Types
 */
export enum FormHandlerType {
  Submit = 0,
  Validate = 1,
  AutocompleteCallback = 2,
  ListSelectorCallback = 3,
  ListSelectorPrepare = 4,
  AfterBuild = 5,
  ElementFormat = 6,
  InputElementAsyncValue = 7,
  RunCustomCallback = 8
}


/**
 */
export class FormElementExecuteElementHandlerRequest implements IFormElementExecuteElementHandlerRequest {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementExecuteElementHandlerRequest';
  readonly $type: string = FormElementExecuteElementHandlerRequest.$type;
  FieldSelector: string;
  HandlerType: FormHandlerType;
}


/**
 * Se usa para solicitar el formato de entidades de las que solo se conoce el ID
 */
export class FormElementInputAsyncValueCallbackRequest extends FormElementExecuteElementHandlerRequest implements IFormElementExecuteElementHandlerRequest {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementInputAsyncValueCallbackRequest';
  readonly $type: string = FormElementInputAsyncValueCallbackRequest.$type;
}


/**
 * This is a data argument for the RunCustomCallback handler launched when the API is triggered.
 */
export class FormElementRunCustomCallbackApiTriggerData implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementRunCustomCallbackApiTriggerData';
  readonly $type: string = FormElementRunCustomCallbackApiTriggerData.$type;
  HandlerId: string;
}


/**
 * FormElementRunCustomCallbackHandlerRequest
 */
export class FormElementRunCustomCallbackHandlerRequest extends FormElementExecuteElementHandlerRequest implements IFormElementExecuteElementHandlerRequest {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementRunCustomCallbackHandlerRequest';
  readonly $type: string = FormElementRunCustomCallbackHandlerRequest.$type;
  HandlerId: string;
}


/**
 * Result for the callback
 */
export class FormElementRunCustomCallbackRequestResult implements ICallbackResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementRunCustomCallbackRequestResult';
  readonly $type: string = FormElementRunCustomCallbackRequestResult.$type;
  OverridedValues: Object;
}


/**
 * Al buscar, solo traer de vuelta los identificadores, el pintado está desacoplado
 */
export class FormElementAutocompleteNewCallbackResult implements ICallbackResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementAutocompleteNewCallbackResult';
  readonly $type: string = FormElementAutocompleteNewCallbackResult.$type;
  Options: FormElementOption[];
  LastPage?: boolean;
}


/**
 * Request a search in a form element autocomplete item
 */
export class FormElementAutocompleteRequest extends FormElementExecuteElementHandlerRequest implements IFormElementExecuteElementHandlerRequest {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementAutocompleteRequest';
  readonly $type: string = FormElementAutocompleteRequest.$type;
  Search: string;
  Page: number;
  RetrieveKeys: CoreHashedKey[];
}


/**
 * Se usa para solicitar el formato de entidades de las que solo se conoce el ID
 */
export class FormElementFormatItemsRequest extends FormElementExecuteElementHandlerRequest implements IFormElementExecuteElementHandlerRequest {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementFormatItemsRequest';
  readonly $type: string = FormElementFormatItemsRequest.$type;
  Keys: CoreHashedKey[];
}


/**
 * Request a search in a form element autocomplete item
 */
export class FormElementFormatItemsRequestResult implements ICallbackResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementFormatItemsRequestResult';
  readonly $type: string = FormElementFormatItemsRequestResult.$type;
  Options: FormElementOptionExtended[];
}


/**
 * Configuración del editor RICH de frontend https://www.npmjs.com/package/@kolkov/angular-editor
 */
export class FormElementRichTextEditorConfigurationFont implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementRichTextEditorConfigurationFont';
  readonly $type: string = FormElementRichTextEditorConfigurationFont.$type;
  Class: string;
  Name: string;
  Tag: string;
}


/**
 * Configuración del editor RICH de frontend https://www.npmjs.com/package/@kolkov/angular-editor
 */
export class FormElementRichTextEditorConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementRichTextEditorConfiguration';
  readonly $type: string = FormElementRichTextEditorConfiguration.$type;
  SpelCheck: boolean;
  Height: string;
  MinHeight: string;
  MaxHeight: string;
  Width: string;
  MinWidth: string;
  Translate: boolean;
  EnableToolbar: boolean;
  ShowToolbar: boolean;
  DefaultParagraphSeparator: string;
  DefaultFontName: string;
  DefaultFontSize: string;
  Sanitize: string;
  ToolbarPosition: string;
  RawPaste: boolean;
  CustomClasses: FormElementRichTextEditorConfigurationFont[];
  Fonts: FormElementRichTextEditorConfigurationFont[];
}


/**
 */
export class FormElementListSelectorPrepareObject implements ICallbackResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementListSelectorPrepareObject';
  readonly $type: string = FormElementListSelectorPrepareObject.$type;
  plugin: string;
  args: Object;
}


/**
 * DTO for Element List Selector Requests
 */
export class FormElementListSelectorRequest extends FormElementExecuteElementHandlerRequest implements IFormElementExecuteElementHandlerRequest {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementListSelectorRequest';
  readonly $type: string = FormElementListSelectorRequest.$type;
  IdsList: string[];
}


/**
 * DTO for Element List Selector Result
 */
export class FormElementListSelectorResult implements ICallbackResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementListSelectorResult';
  readonly $type: string = FormElementListSelectorResult.$type;
  Options: FormElementOptionExtended[];
}


/**
 * Result class to model individual form element validation results
 */
export class FormElementValidationResult implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementValidationResult';
  readonly $type: string = FormElementValidationResult.$type;
  ElementId: string;
  Messages: FormValidationError[];
}


/**
 * Normalized result for a FormElementAutocompleteCallback
 */
export class FormElementAutocompleteCallbackResult implements ICallbackResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementAutocompleteCallbackResult';
  readonly $type: string = FormElementAutocompleteCallbackResult.$type;
  Options: FormElementOption[];
  IsPaged?: boolean;
}


/**
 * Basic form element for INPUT management.
 */
export class FormElementInput extends FormElement implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementInput';
  readonly $type: string = FormElementInput.$type;
  DefaultValue: any;
  Placeholder: string;
  Required: boolean;
  ShowRemainingCharCounter: boolean;
  DoNotPropagateValidationErrorsToFormSummary: boolean;
  CharacterCounterMode: FormElementInputCharacterCounterModeEnum;
  FrontedUserValueRestoreKey: string;
  MaxLength: number;
  MinLength: number;
  AsyncValidation: boolean;
  ConfirmField: string;
  CssClassesLabel: string[];
  CssClassesValue: string[];
  CssClassesDescription: string[];
  CssClassesTooltip: string[];
  Validators: { [id: string]: IFormElementInputValidator };
}


/**
 * Base class for form elements that have a fixed set of available options that are populated during the form build process (i.e. select, dropdown, radios, checkboxes, etc.)
 */
export class FormElementFixedOptionsBase extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementFixedOptionsBase';
  readonly $type: string = FormElementFixedOptionsBase.$type;
  Options: IFormElementOption[];
  IsMultiselect: boolean;
  RemoveInvalidOptionsOnRender: boolean;
  DisableAutoSort?: boolean;
  DefaultValue: any;
}


/**
 * Form element input character counter mode enum.  This enum is used in the method to set the counter type. https://easytech.atlassian.net/browse/ACHSPRIME-3649
 */
export enum FormElementInputCharacterCounterModeEnum {
  BlockCharacterExcedents = 0,
  AllowCharacterExcedents = 1
}


/**
 */
export interface IFormElementOption extends ICssClassesProperty, ITypedObject {
  Key: string;
  Weight?: number;
  Name: any;
  Description: any;
}


/**
 * Represents an option in a multiple select element (select, checkboxes, radio)  TODO: Quitar selected (se usan los values) y renombrar Key a "Value" y "Name" a Label.
 */
export class FormElementOptionTranslatable implements IFormElementOption {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementOptionTranslatable';
  readonly $type: string = FormElementOptionTranslatable.$type;
  Key: string;
  HashedKey: CoreHashedKey;
  Name: string;
  Description: string;
  Weight?: number;
  CssClasses: string[];
  Metadata: { [id: string]: any };
}


/**
 * Represents a Element File
 */
export class FormElementFile extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementFile';
  readonly $type: string = FormElementFile.$type;
  Type: FormElementType;
  ValidExtensions: string[];
  ValidSize: number;
  MaxFiles: number;
  EnableDragAndDrop: boolean;
  DefaultComponentMode: FileComponentMode;
  DisableAutomaticUpload: boolean;
  ButtonLabel: string;
  DefaultValue: any;
}


/**
 * Represents a Element File
 */
export class FormElementAvatar extends FormElementFile implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementAvatar';
  readonly $type: string = FormElementAvatar.$type;
  Type: FormElementType;
  ValidExtensions: string[];
  MaxWidth?: number;
  MaxHeight?: number;
  MinWidth?: number;
  MinHeight?: number;
  MaxFiles: number;
  DefaultValue: any;
}


/**
 */
export enum FileComponentMode {
  Pretty = 1,
  List = 2
}


/**
 * Basic form element for a batch task
 */
export class FormElementBatchTask extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementBatchTask';
  readonly $type: string = FormElementBatchTask.$type;
  Type: FormElementType;
  DefaultValue: any;
}


/**
 * Clase base para elementos compuestos / custom
 */
export class FormElementCompoundBase extends FormElementInput implements IFormElementInput, IFormElementGroup {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementCompoundBase';
  readonly $type: string = FormElementCompoundBase.$type;
  Type: FormElementType;
  ElementPrefix: string;
  DisableBootstrapColumns?: boolean;
  ValueJpath: string;
}


/**
 */
export interface ITimeZonedFormElement extends IFormElement, ITypedObject {
  TimeZone: string;
  SkipLoadTimeZoneOnMissing: boolean;
  AutoLoadTimeZonePolicy: TimeZoneConversionPolicy;
}


/**
 * IDEAS: En front user Date object para los datos en backend un DateTime https://valor-software.com/ngx-bootstrap/#/datepicker  IMPORTANTE SOBRE ESTE COMPONENTE:  El componente trabaja internamente siempre con Unix Timestamp, lo que permite que tenga comportamiento homogéneo y automático con la API de estados, comparación de valores, etc.  El timezone se utiliza únicamnete en el pintado de frontend, para que el usuario vea la fecha y hora en su zona horaria.
 */
export class FormElementDateTimePicker extends FormElementInput implements IFormElementInput, ITimeZonedFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementDateTimePicker';
  readonly $type: string = FormElementDateTimePicker.$type;
  Precision: Precision;
  MaxDateEffective?: number;
  MinDateEffective?: number;
  MinDate: Date;
  MaxDate: Date;
  Type: FormElementType;
  TimeZoneInfo: FormElementDatePickerTimeZoneInfo;
  TimeZone: string;
  SkipLoadTimeZoneOnMissing: boolean;
  AutoLoadTimeZonePolicy: TimeZoneConversionPolicy;
  DefaultValue: any;
}


/**
 * IDEAS: En front user Date objet para los datos en backen un DateTime https://valor-software.com/ngx-bootstrap/#/datepicker
 */
export class FormElementDatePicker extends FormElementDateTimePicker implements IFormElementInput, ITimeZonedFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementDatePicker';
  readonly $type: string = FormElementDatePicker.$type;
  Type: FormElementType;
  Precision: Precision;
}


/**
 * Multiple option (dropdown) select element.
 */
export class FormElementFromContentsProgress extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementFromContentsProgress';
  readonly $type: string = FormElementFromContentsProgress.$type;
  Type: FormElementType;
  ProgressElements: string[];
  ProgressUpdateOnChange: boolean;
  ProgressUseSteps: boolean;
  ProgressStepValue: number;
}

export class FormElementSelectDynamic extends FormElementFixedOptionsBase implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementSelectDynamic';
  readonly $type: string = FormElementSelectDynamic.$type;
  Type: FormElementType;
  AddNewItemFormPluginRequest: SelectDynamicFormRequest;
  CreateNewItem: boolean;
  CreateNewItemTitle: string;
  PreloadedOptions: FormElementOptionExtended[];
  CreateNewItemPermissions: string;
}


/**
 */
export enum FormElementHtmlPlaceholderEmbedType {
  Unsafe = 0,
  SafeHtml = 1,
  SafeHtmlWithScripts = 2,
  Iframe = 3
}


/**
 * Basic form element for messages.
 */
export class FormElementSimpleMessage extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementSimpleMessage';
  readonly $type: string = FormElementSimpleMessage.$type;
  Type: FormElementType;
  Messages: ICoreRenderTemplate;
}


/**
 * Basic form element for LABEL management.
 */
export class FormElementNavigationBoard extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementNavigationBoard';
  readonly $type: string = FormElementNavigationBoard.$type;
  Type: FormElementType;
  Controller: string;
  DefaultValue: any;
}


/**
 * Class for address mapping on google maps geocoding service
 */
export class FormElementAddressMapping implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementAddressMapping';
  readonly $type: string = FormElementAddressMapping.$type;
  street_number: string;
  route: string;
  locality: string;
  administrative_area_level_2: string;
  administrative_area_level_1: string;
  country: string;
  postal_code: string;
}


/**
 * Interface with the access property
 */
export interface IMultiSelect extends ITypedObject {
  IsMultiselect: boolean;
}


/**
 * No results message
 */
export interface INoResultsMessage extends ITypedObject {
  NoResultsMessage: string;
}


/**
 * Multiple option (dropdown) select element.
 */
export class FormElementAutocompleteNew extends FormElementInput implements IFormElementInput, IMultiSelect, INoResultsMessage {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementAutocompleteNew';
  readonly $type: string = FormElementAutocompleteNew.$type;
  PreloadedOptions: FormElementOption[];
  Type: FormElementType;
  IsMultiselect: boolean;
  NoResultsMessage: string;
  CreateNewItemTitle: string;
  CreateNewItemPermissions: string;
  CreateNewItemFormPluginRequest: AutocompleteNewFormRequest;
  ShowCreateNewItem: boolean;
  EditItemTitle: string;
  EditItemPermissions: string;
  EditItemFormPluginRequest: AutocompleteNewFormRequest;
  ShowEditItem: boolean;
  DefaultValue: any;
}


/**
 * Multiple checkboxes modal
 */
export class FormElementCheckboxesModal extends FormElementFixedOptionsBase implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementCheckboxesModal';
  readonly $type: string = FormElementCheckboxesModal.$type;
  IsMultiselect: boolean;
  Type: FormElementType;
  ShowSelectAll: boolean;
  MaxItems: number;
  MaxItemsToShowInBar: number;
  DefaultValue: any;
}


/**
 * Buttons in forms
 */
export class FormElementFrontendCustom extends FormElement implements IFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementFrontendCustom';
  readonly $type: string = FormElementFrontendCustom.$type;
  Type: FormElementType;
  BindingControlName: string;
  ControlArguments: { [id: string]: any };
}


/**
 * Multiple option (dropdown) select tag.  TODO: This shold NOT inherit from FormElementFixedOptionsBase because FormElementFixedOptionsBase should make assumptions according to preoladed data such as options validations, all optionas available, etc.
 */
export class FormElementChips extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementChips';
  readonly $type: string = FormElementChips.$type;
  Type: FormElementType;
  DefaultValue: any;
}


/**
 */
export class FormElementBool extends FormElementFixedOptionsBase implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementBool';
  readonly $type: string = FormElementBool.$type;
  IsMultiselect: boolean;
  Type: FormElementType;
}


/**
 * Single checkbox
 */
export class FormElementCheckbox extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementCheckbox';
  readonly $type: string = FormElementCheckbox.$type;
  Type: FormElementType;
}


/**
 * Basic form element for an embedded document.
 */
export class FormElementEmbeddedDocument extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementEmbeddedDocument';
  readonly $type: string = FormElementEmbeddedDocument.$type;
  Type: FormElementType;
  DefaultValue: any;
}


/**
 * Maps in forms
 */
export class FormElementMapInput extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementMapInput';
  readonly $type: string = FormElementMapInput.$type;
  Type: FormElementType;
  AddressMapping: FormElementAddressMapping;
  LatitudeElementKey: string;
  LongitudeElementKey: string;
  DefaultValue: any;
}


/**
 */
export class FormElementQRCode extends FormElement implements IFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementQRCode';
  readonly $type: string = FormElementQRCode.$type;
  Type: FormElementType;
  DefaultValue: FormElementQRCodeValueForm;
  Size: number;
}


/**
 * IDEAS: En front user Date objet para los datos en backen un DateTime https://valor-software.com/ngx-bootstrap/#/datepicker
 */
export class FormElementTimePickerTimeSpan extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementTimePickerTimeSpan';
  readonly $type: string = FormElementTimePickerTimeSpan.$type;
  MinTime: any;
  MaxTime: any;
  Type: FormElementType;
  DefaultValue: any;
}


/**
 * IDEAS: En front user Date objet para los datos en backen un DateTime https://valor-software.com/ngx-bootstrap/#/datepicker
 */
export class FormElementTimePicker extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementTimePicker';
  readonly $type: string = FormElementTimePicker.$type;
  Type: FormElementType;
  DefaultValue: any;
}


/**
 * Basic form element for LABEL management.
 */
export class FormElementHtmlPlaceholder extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementHtmlPlaceholder';
  readonly $type: string = FormElementHtmlPlaceholder.$type;
  Type: FormElementType;
  Html: string;
  EnableUrlify: boolean;
  EmbedType: FormElementHtmlPlaceholderEmbedType;
}


/**
 * Represents an option in a multiple select element (select, checkboxes, radio)  TODO: Quitar selected (se usan los values) y renombrar Key a "Value" y "Name" a Label.
 */
export class FormElementOption implements IFormElementOption {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementOption';
  readonly $type: string = FormElementOption.$type;
  Key: string;
  HashedKey: CoreHashedKey;
  Name: string;
  Weight?: number;
  CssClasses: string[];
  Description: string;
  Metadata: { [id: string]: any };
}


/**
 * FormElementOption que se utiliza en el componente FormElementViewsSelector para pintar el resumen de los elementos
 */
export class FormElementOptionViewsSelector extends FormElementOption implements IFormElementOption {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementOptionViewsSelector';
  readonly $type: string = FormElementOptionViewsSelector.$type;
  SummaryDetails: { [id: string]: string };
}


/**
 * Elemento que sirve para embedir una gráfica en un formulario
 */
export class FormElementChartEmbedded extends FormElement implements IFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementChartEmbedded';
  readonly $type: string = FormElementChartEmbedded.$type;
  Type: FormElementType;
  DefaultValue: IFormElementChartEmbeddedValue;
}


/**
 * Clase compartida para elementos de formulario que soportan interacción con listados
 */
export class FormElementViewsSupportBase extends FormElementInput implements IFormElementInput, IMultiSelect {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementViewsSupportBase';
  readonly $type: string = FormElementViewsSupportBase.$type;
  IsMultiselect: boolean;
  ViewsId: string;
  ViewsFieldId: string;
  ViewsArguments: Object;
  ViewsParameters: { [id: string]: IViewRequestParameter };
}


/**
 * Clase compartida para elementos de formulario que soportan interacción con listados
 */
export class FormElementViewsSupportBaseEmbed extends FormElementViewsSupportBase implements IFormElementInput, IMultiSelect {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementViewsSupportBaseEmbed';
  readonly $type: string = FormElementViewsSupportBaseEmbed.$type;
  MaterializeItemsOnSubmit: boolean;
}


/**
 * Elemento que sirve para embedir una vista en un formulario y así utilizarla como selector de elementos.
 */
export class FormElementViewsEmbed extends FormElementViewsSupportBaseEmbed implements IFormElementInput, IMultiSelect {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementViewsEmbed';
  readonly $type: string = FormElementViewsEmbed.$type;
  Type: FormElementType;
  DoNotRefreshOnVisibilityChange?: boolean;
  DefaultValue: any;
}


/**
 * Es un componente que permite tener selección de elementos que provienen de un listado abierto en una pop-up, y que se renderizan en cajitas resumen en el formulario.
 */
export class FormElementViewsSelector extends FormElementViewsSupportBase implements IFormElementInput, IMultiSelect {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementViewsSelector';
  readonly $type: string = FormElementViewsSelector.$type;
  Type: FormElementType;
  DefaultValue: any;
}


/**
 * Maps in forms
 */
export class FormElementMap extends FormElement implements IFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementMap';
  readonly $type: string = FormElementMap.$type;
  Type: FormElementType;
  LatitudeElementKey: string;
  LongitudeElementKey: string;
  AddressMapping: FormElementAddressMapping;
  EditableMap: boolean;
}


/**
 * FormElementOption extended with description and Extra Information
 */
export class FormElementOptionExtended extends FormElementOption implements IFormElementOption {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementOptionExtended';
  readonly $type: string = FormElementOptionExtended.$type;
  ExtraInformation: string[];
  Html: string;
}


/**
 * This is a special form element used by the form API to make form elements with Access = FALSE protected while in-transport (on client side).  Not that this is top-secret or anything, but to prevent information disclosure some form elements where the use does not have
 */
export class FormElementSecret extends FormElement implements IFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementSecret';
  readonly $type: string = FormElementSecret.$type;
  Type: FormElementType;
  type: string;
  serialized: string;
}


/**
 * Represents a Element TextArea
 */
export class FormElementInputPassword extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementInputPassword';
  readonly $type: string = FormElementInputPassword.$type;
  Type: FormElementType;
}


/**
 */
export class FormElementTable extends FormElement implements IFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementTable';
  readonly $type: string = FormElementTable.$type;
  Type: FormElementType;
  Rows: { [id: string]: FormElementTableRowConfig };
  InnerComponentNamingPrefix: string;
}


/**
 * Buttons in forms
 */
export class FormElementButton extends FormElement implements IFormElement {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementButton';
  readonly $type: string = FormElementButton.$type;
  Type: FormElementType;
  Action: FormElementAction;
  ActionsExtended: { [id: string]: ICommand };
  Metadata: Object;
  ValidateFields: string[];
  DoNotExecuteFormSubmitHandlers: boolean;
  ConfirmChangeMessage: FormInputChangeAlert;
  ButtonClasses: string[];
  DisableIfDirty: boolean;
  DisableIfDirtyTooltipMessage: string;
  DisableIfDirtyTarget: string;
  DisableIfInvalid: boolean;
  DisableIfInvalidTooltipMessage: string;
  DisableIfInvalidTarget: string;
  RequireClientValidations: boolean;
}


/**
 * Basic form element for Date management.  Very important information: Dates should always be managed in UnixTimestamp and using UTC as the reference time zone. The Date is painted in the user's form using it's LOCAL TIME ZONE so it might differ to what is sent by the server, but when it comes back to the user it is back in UTC again.
 */
export class FormElementDate extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementDate';
  readonly $type: string = FormElementDate.$type;
  Type: FormElementType;
  DatePart: FormElementDatePart;
  MinDate?: number;
  MaxDate?: number;
}


/**
 * FormElement Date Part
 */
export enum FormElementDatePart {
  DateTime = 0,
  Date = 1,
  Time = 2,
  Year = 3,
  Month = 4,
  Day = 5
}


/**
 * Type created only for numeric input
 */
export class FormElementInputNumber extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementInputNumber';
  readonly $type: string = FormElementInputNumber.$type;
  Type: FormElementType;
  MinValue: number;
  MaxValue?: number;
  Step?: number;
  DefaultValue: any;
}


/**
 * Multiple option (dropdown) select element.
 */
export class FormElementRadio extends FormElementFixedOptionsBase implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementRadio';
  readonly $type: string = FormElementRadio.$type;
  IsMultiselect: boolean;
  ShowCheckIfNotEmpty?: boolean;
  Type: FormElementType;
}


/**
 * Represents a Element TextArea
 */
export class FormElementTextArea extends FormElementInput implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementTextArea';
  readonly $type: string = FormElementTextArea.$type;
  Type: FormElementType;
  Rows: number;
  ResizePolicy: FormTextAreaResizePolicy;
  RichTextConfiguration: FormElementRichTextEditorConfiguration;
}


/**
 * Represents a group of fields
 */
export class FormElementFieldSet extends FormElement implements IFormElement, IFormElementGroup {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementFieldSet';
  readonly $type: string = FormElementFieldSet.$type;
  Type: FormElementType;
  ReadonlyInheritanceMode: FormElementReadOnlyPropagationMode;
  FieldsetType: FormFieldsetType;
  Collapsed: boolean;
  PreserveCollapsedValueKey: string;
  DisableBootstrapColumns?: boolean;
}


/**
 * Multiple checkboxes
 */
export class FormElementCheckboxes extends FormElementFixedOptionsBase implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementCheckboxes';
  readonly $type: string = FormElementCheckboxes.$type;
  IsMultiselect: boolean;
  Type: FormElementType;
}


/**
 * Multiple option (dropdown) select element.
 */
export class FormElementAutocomplete extends FormElementInput implements IFormElementInput, IMultiSelect {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementAutocomplete';
  readonly $type: string = FormElementAutocomplete.$type;
  Type: FormElementType;
  IsMultiselect: boolean;
  DefaultValue: any;
}


/**
 * Multiple option (dropdown) select element.
 */
export class FormElementSelect extends FormElementFixedOptionsBase implements IFormElementInput {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementSelect';
  readonly $type: string = FormElementSelect.$type;
  IsMultiselect: boolean;
  Type: FormElementType;
}


/**
 */
export class FormElementQRCodeValueForm implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.QrCode.FormElementQRCodeValueForm';
  readonly $type: string = FormElementQRCodeValueForm.$type;
  PluginRequest: FormPluginRequest;
}


/**
 * IFormElementChartEmbeddedValue
 */
export interface IFormElementChartEmbeddedValue extends ITypedObject {
}


/**
 * FormElementChartEmbeddedValue
 */
export class FormElementChartEmbeddedValue implements IFormElementChartEmbeddedValue {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.ChartEmbedded.FormElementChartEmbeddedValue';
  readonly $type: string = FormElementChartEmbeddedValue.$type;
  ChartId: string;
  ChartsArguments: Object;
  SkipCache?: boolean;
  DeferredExecution?: boolean;
}


/**
 * DTO intefrace for FormElementEmbeddedDocument
 */
export interface IEmbeddedDocument extends ITypedObject {
}


/**
 * DTO to use a document file in a FormElementEmbeddedDocument
 */
export class EmbeddedDocumentFile implements IEmbeddedDocument {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.EmbeddedDocument.EmbeddedDocumentFile';
  readonly $type: string = EmbeddedDocumentFile.$type;
  FileContext: string;
  ContextId: string;
}


/**
 * DTO to use a CORE_QUEUE id in a FormElementEmbeddedDocument
 */
export class EmbeddedDocumentQueue implements IEmbeddedDocument {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.EmbeddedDocument.EmbeddedDocumentQueue';
  readonly $type: string = EmbeddedDocumentQueue.$type;
  BatchTaskId: string;
}


/**
 * DTO to use a task in a FormElementEmbeddedDocument
 */
export class EmbeddedDocumentTask implements IEmbeddedDocument {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.EmbeddedDocument.EmbeddedDocumentTask';
  readonly $type: string = EmbeddedDocumentTask.$type;
  Task: BatchTaskInfo;
}


/**
 * DTO to use a document template in a FormElementEmbeddedDocument
 */
export class EmbeddedDocumentTemplate implements IEmbeddedDocument {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.EmbeddedDocument.EmbeddedDocumentTemplate';
  readonly $type: string = EmbeddedDocumentTemplate.$type;
  Process: string;
  Template?: string;
  ExecutionArgs: any;
}


/**
 * DTO to use a UploadedFile id in a FormElementEmbeddedDocument
 */
export class EmbeddedDocumentUploadedFiles implements IEmbeddedDocument {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.EmbeddedDocument.EmbeddedDocumentUploadedFiles';
  readonly $type: string = EmbeddedDocumentUploadedFiles.$type;
  Files: UploadedFile[];
}

/**
 * Campos adicionales para la petición de formualrio en el select
 */
export class SelectDynamicFormRequest extends FormPluginRequest implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.SelectDynamic.SelectDynamicFormRequest';
  readonly $type: string = SelectDynamicFormRequest.$type;
  ModalSettings: DtoFrontendModal;
}

/**
 * Campos adicionales para la petición de formualrio en el autocomplete
 */
export class AutocompleteNewFormRequest extends FormPluginRequest implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.AutocompleteNew.AutocompleteNewFormRequest';
  readonly $type: string = AutocompleteNewFormRequest.$type;
  ModalSettings: DtoFrontendModal;
}

/**
 */
export class FormElementSelectDynamicRequest extends FormElementExecuteElementHandlerRequest implements IFormElementExecuteElementHandlerRequest {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Dto.FormElementSelectDynamicRequest';
  readonly $type: string = FormElementSelectDynamicRequest.$type;
  Keys: CoreHashedKey[];
}

/**
 */
export interface ICoreFormCommand extends ICommand, ITypedObject {
  FormIdRegex: string;
}


/**
 */
export class CoreFormCommandBase implements ICoreFormCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreFormCommandBase';
  readonly $type: string = CoreFormCommandBase.$type;
  FormIdRegex: string;
  FormState: IFormState;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Emite el evento de success de un formulario, lo usan listeners para identificar que el flujo de un fomrulario ha terminado para hacer cosas como, por ejemplo, cerrar la modal donde está el formulario.
 */
export class CoreFormSetFormStatusCommand extends CoreFormCommandBase implements ICoreFormCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreFormSetFormStatusCommand';
  readonly $type: string = CoreFormSetFormStatusCommand.$type;
  Status: FormStatusEnum[];
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Comando que permite sobreescribir el valor de un componente en el values. Será útil para evitar rebuilds cuando queremos actualizar el valor de un campo.
 */
export class CoreFormSetValueCommand extends CoreFormCommandBase implements ICoreFormCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreFormSetValueCommand';
  readonly $type: string = CoreFormSetValueCommand.$type;
  OverridedValues: { [id: string]: any };
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Emite el evento de success de un formulario, lo usan listeners para identificar que el flujo de un fomrulario ha terminado para hacer cosas como, por ejemplo, cerrar la modal donde está el formulario.
 */
export class CoreFormSuccessEventCommand extends CoreFormCommandBase implements ICoreFormCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreFormSuccessEventCommand';
  readonly $type: string = CoreFormSuccessEventCommand.$type;
  Result: any;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Open a form in a modal
 */
export class CoreOpenFormInModalCommand extends DtoFrontendModal implements ICssClassesProperty, ITitleProperty, ICommandWithArguments {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreOpenFormInModalCommand';
  readonly $type: string = CoreOpenFormInModalCommand.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
  FormId: string;
  DynamicComponents: { [id: string]: string };
  Arguments: { [id: string]: any };
  ArgumentValues: any[];
  OnCloseCommands: { [id: string]: ICommand };
}


/**
 * Es una extensión del commando de mostrado de modales, que simplemente hace que la modal se muestre únicamente si el formulario está "pristine".
 */
export class CoreFormConfirmModalIfDirtyCommand extends CoreModalConfirmMessageCommand implements ICssClassesProperty, ITitleProperty, ICoreFormCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreFormConfirmModalIfDirtyCommand';
  readonly $type: string = CoreFormConfirmModalIfDirtyCommand.$type;
  FormIdRegex: string;
}


/**
 * Resetear un formulario
 */
export class CoreFormSubmitFormCommand extends CoreFormCommandBase implements ICoreFormCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreFormSubmitFormCommand';
  readonly $type: string = CoreFormSubmitFormCommand.$type;
  Emitter: string;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Resetear un formulario
 */
export class CoreFormScrollToTopCommand extends CoreFormCommandBase implements ICoreFormCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreFormScrollToTopCommand';
  readonly $type: string = CoreFormScrollToTopCommand.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Resetear un formulario
 */
export class CoreFormResetFormCommand extends CoreFormCommandBase implements ICoreFormCommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Commands.CoreFormResetFormCommand';
  readonly $type: string = CoreFormResetFormCommand.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Constants for the content disposition options allowed by the app.  It's compiled to frontend for proper service consumption.
 */
export class FileContentDispositionOptions implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Files.FileContentDispositionOptions';
  public static readonly Attachment: string = 'attachment';
  public static readonly Inline: string = 'inline';
  readonly $type: string = FileContentDispositionOptions.$type;
}


/**
 * The status of a file
 */
export enum FileStatusEnum {
  Temporary = 0,
  Upload = 1,
  Permanent = 2,
  PermanentWithoutFileUsage = 4
}


/**
 * Descargar ficheros
 */
export class CoreFileDownload implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Files.Commands.CoreFileDownload';
  readonly $type: string = CoreFileDownload.$type;
  Field: string;
  Url: string;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * This is the default upload configuration for a file context
 */
export class FileContextSettings implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Files.Dto.FileContextSettings';
  readonly $type: string = FileContextSettings.$type;
  AllowedExtensions: string[];
  DestinationFilePath: string;
  MaximumFileSize: number;
  MaximumNumberOfFiles: number;
  MaximumStorageSize: number;
}


/**
 * Configuración de un formulario de carga de ficheros
 */
export class FileContextClientConfiguration implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Files.Dto.FileContextClientConfiguration';
  readonly $type: string = FileContextClientConfiguration.$type;
  ContextName: string;
  ContextIdGuid: string;
}


/**
 * A specific DTO to communicate with frontend component FineUploader
 */
export class FileManagerResult implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Files.Dto.FileManagerResult';
  readonly $type: string = FileManagerResult.$type;
  success: boolean;
  uuid: string;
  preventRetry: boolean;
  error: string;
  file: UploadedFile;
}


/**
 * DTO que representa un enlace de descarga disponible en frontend durante un tiempo determinado
 */
export class FileRef implements ISignedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Files.Dto.FileRef';
  readonly $type: string = FileRef.$type;
  Uri: string;
  Expires?: number;
  ContentDisposition: string;
  DeleteAfterServe: boolean;
  Signature: string;
  SaltType?: any;
}


/**
 */
export class UploadedFile implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Files.Dto.UploadedFile';
  readonly $type: string = UploadedFile.$type;
  name: string;
  size?: number;
  type: string;
  id: string;
  url: string;
}


/**
 * This is actually a normalized and serializable representation for logging/debuging purposes.  Such level of detail (indeed any level at all) should never be passed down to clients.
 */
export class NormalizedException implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Error.NormalizedException';
  readonly $type: string = NormalizedException.$type;
  id: string;
  message: string;
  type: string;
  ExceptionType: string;
  data: { [id: string]: any };
  stackTrace: string;
  metadata: { [id: string]: any };
  inner: NormalizedException;
  innerExceptions: NormalizedException[];
  ticketid: string;
}


/**
 * Utilizamos esto para poder envíar a frontend claves con hash, de esta manera podemos blindar los valores de INPUT de la API de formularios haciendo únicamente verificaciones en el HASH en lugar de tener que comprobar si la opción existía en las opciones expuestas al usuario.
 */
export class CoreHashedKey implements ISignedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Crypto.CoreHashedKey';
  readonly $type: string = CoreHashedKey.$type;
  Key: string;
  Signature: string;
  SaltType?: any;
}


/**
 * App Context
 */
export class Context implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Context.Dto.Context';
  readonly $type: string = Context.$type;
  userLanguage: string;
}


/**
 * Navegar en una dirección concreta
 */
export class CoreContextSetCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Context.Command.CoreContextSetCommand';
  readonly $type: string = CoreContextSetCommand.$type;
  Context: string;
  Sync: boolean;
  Id: string;
  Weight: number;
  Async: boolean;
}


/**
 * Comando para redirigir al usuario a una URL externa (diferente al comando de navegación interna)
 */
export class CoreCommandRedirectUser implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Command.CoreCommandRedirectUser';
  readonly $type: string = CoreCommandRedirectUser.$type;
  Url: string;
  UsePost: string;
  FormData: { [id: string]: string };
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Comando que permite ejecutar un callback, a nivel de seguridad los callbacks vienen firmados
 */
export class CoreCommandCallbackTyped implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Command.CoreCommandCallbackTyped';
  readonly $type: string = CoreCommandCallbackTyped.$type;
  StopPropagationOnError: boolean;
  Callback: CallbackTyped;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Comando para resetear la aplicación en frontend
 */
export class CoreCommandReloadApplication implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Command.CoreCommandReloadApplication';
  readonly $type: string = CoreCommandReloadApplication.$type;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Comando para refrescar un chart
 */
export class CoreChartsCommandRefreshChart implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Command.CoreChartsCommandRefreshChart';
  readonly $type: string = CoreChartsCommandRefreshChart.$type;
  ChartId: string;
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * A Chart Filter
 */
export interface IChartFilter extends ITypedObject {
  FormElement: FormElementInput;
  Id: string;
}


/**
 * Chart Filter: Text
 */
export class ChartCheckFilter implements IChartFilter {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.Filters.Filters.ChartCheckFilter';
  readonly $type: string = ChartCheckFilter.$type;
  Id: string;
  FormElement: FormElementInput;
}


/**
 * Chart Filter: FormElementAutocompleteNew
 */
export class ChartFilterAutocomplete implements IChartFilter {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.Filters.Filters.ChartFilterAutocomplete';
  readonly $type: string = ChartFilterAutocomplete.$type;
  Id: string;
  FormElement: FormElementInput;
}


/**
 * Chart Filter: Date Time Picker
 */
export class ChartFilterDatePicker implements IChartFilter {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.Filters.Filters.ChartFilterDatePicker';
  readonly $type: string = ChartFilterDatePicker.$type;
  Id: string;
  FormElement: FormElementInput;
}


/**
 * Chart Filter: Date Time Picker
 */
export class ChartFilterDateTimePicker implements IChartFilter {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.Filters.Filters.ChartFilterDateTimePicker';
  readonly $type: string = ChartFilterDateTimePicker.$type;
  Id: string;
  FormElement: FormElementInput;
}


/**
 * Chart Filter: Select
 */
export class ChartFilterSelect implements IChartFilter {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.Filters.Filters.ChartFilterSelect';
  readonly $type: string = ChartFilterSelect.$type;
  Id: string;
  FormElement: FormElementInput;
}


/**
 * Chart Filter: Text
 */
export class ChartFilterText implements IChartFilter {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.Filters.Filters.ChartFilterText';
  readonly $type: string = ChartFilterText.$type;
  Id: string;
  FormElement: FormElementInput;
}


/**
 * Chart Filter: Text
 */
export class ChartNumberFilter implements IChartFilter {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.Filters.Filters.ChartNumberFilter';
  readonly $type: string = ChartNumberFilter.$type;
  Id: string;
  FormElement: FormElementInput;
}


/**
 * Charts Processors: The Charts will be executed.
 */
export interface IChartProcessor extends ITypedObject {
  IsUniqueProcessor: boolean;
  Weight: number;
  Id: string;
}


/**
 * This processor manages the Display Options of the chart
 */
export class ChartProcessorDisplayOptions implements IChartProcessor {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.DisplayOptions.ChartProcessorDisplayOptions';
  readonly $type: string = ChartProcessorDisplayOptions.$type;
  Id: string;
  DisplayOptions: { [id: string]: IChartDisplayOptions };
  CssClasses: string[];
  IsUniqueProcessor: boolean;
  Weight: number;
}


/**
 * This processor manages the Display Options of the chart
 */
export class ChartProcessorAvailableCharts implements IChartProcessor {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Processors.AvailableCharts.ChartProcessorAvailableCharts';
  readonly $type: string = ChartProcessorAvailableCharts.$type;
  Id: string;
  Weight: number;
  IsUniqueProcessor: boolean;
}


/**
 */
export class ExportSettings implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.ExportSettings';
  readonly $type: string = ExportSettings.$type;
  Allowed: boolean;
  DeferredExecution: boolean;
}


/**
 * The Chart Configuration
 */
export class CompiledChart implements ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.CompiledChart';
  readonly $type: string = CompiledChart.$type;
  Title: string;
  Description: string;
  Metadata: ChartMetadata;
  PluginRequest: ChartPluginRequest;
  NoResultMessage: string;
  CurrentChart: string;
  AvailableCharts: { [id: string]: IChart };
  Data: IChartData;
  CssClasses: string[];
  Access?: boolean;
  Actions: { [id: string]: IChartAction };
  Tags: string[];
}


/**
 * ChartMetadata
 */
export class ChartMetadata implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.ChartMetadata';
  readonly $type: string = ChartMetadata.$type;
  BuildStartedAt: Date;
  BuildEndsAt: Date;
}


/**
 */
export interface IChartUserConfiguration extends ITypedObject {
  SelectedChart: string;
}


/**
 * The user configuration for the chart
 */
export class ChartUserConfiguration implements IChartUserConfiguration {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.ChartUserConfiguration';
  readonly $type: string = ChartUserConfiguration.$type;
  SelectedChart: string;
  SignedFormState: string;
  FormSubmitData: FormSubmitData;
}


/**
 * The Response Dto of the Chart system
 */
export class ChartExecutionResponse implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.ChartExecutionResponse';
  readonly $type: string = ChartExecutionResponse.$type;
  PluginRequest: ChartPluginRequest;
  CompiledChart: CompiledChart;
  UserConfiguration: ChartUserConfiguration;
}


/**
 * Chaty Plugin Request. The complete Dto that contains all the Chart information.
 */
export class ChartPluginRequest implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.ChartPluginRequest';
  readonly $type: string = ChartPluginRequest.$type;
  Id: string;
  Arguments: Object;
  DeferredExecution: boolean;
  DeferredQueueId?: string;
  DeferredExecutionRefreshInterval: number;
}


/**
 */
export class ExportSettingsHtmlTable implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.ExportSettingsHtmlTable';
  readonly $type: string = ExportSettingsHtmlTable.$type;
  Allowed: boolean;
  DeferredExecution: boolean;
  LabelsSerieName: string;
  Settings: any;
}


/**
 */
export interface IChartData extends ITypedObject {
}


/**
 */
export class ChartDataBatch implements IChartData {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Data.ChartDataBatch';
  readonly $type: string = ChartDataBatch.$type;
  Operation: BatchTaskInfo;
}


/**
 */
export class ChartDataSeries implements IChartData {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Data.ChartDataSeries';
  readonly $type: string = ChartDataSeries.$type;
  Series: { [id: string]: ISerie };
}


/**
 */
export interface IColorPaletteChartDisplayOption extends ITypedObject {
  ColorScheme: string[];
}


/**
 */
export interface ILegendChartDisplayOption extends ITypedObject {
  ShowLegend: boolean;
  LegendTitle: string;
  LegendPosition: string;
}


/**
 */
export interface ITooltipChartDisplayOption extends ITypedObject {
  TooltipText: string;
  TooltipDisabled: boolean;
}


/**
 */
export interface IChartDisplayOptions extends ITypedObject {
  Hidden: boolean;
}


/**
 */
export interface INGXChartDisplayOptions extends IColorPaletteChartDisplayOption, ILegendChartDisplayOption, ITooltipChartDisplayOption, IChartDisplayOptions, ITypedObject {
  ViewWidth?: number;
  ViewHeight?: number;
}


/**
 */
export class ColorPaletteChartDisplayOption implements IColorPaletteChartDisplayOption {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.CommonDisplayOptions.ColorPaletteChartDisplayOption';
  readonly $type: string = ColorPaletteChartDisplayOption.$type;
  ColorScheme: string[];
}


/**
 * Abstract class for all NGX Charts
 */
export class NGXChartDisplayOptions extends ColorPaletteChartDisplayOption implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.CommonDisplayOptions.NGXChartDisplayOptions';
  readonly $type: string = NGXChartDisplayOptions.$type;
  ShowLegend: boolean;
  LegendTitle: string;
  LegendPosition: string;
  TooltipText: string;
  TooltipDisabled: boolean;
  Hidden: boolean;
  ViewWidth?: number;
  ViewHeight?: number;
}


/**
 * Interface for all chart implementations
 */
export interface IChart extends ITypedObject {
  ChartDisplayOptions: IChartDisplayOptions;
  ExportableAsImageSettings: ExportSettings;
  ExportableAsExcelSettings: ExportSettings;
  ExportableAsHtmlTableSettings: ExportSettingsHtmlTable;
  Id: string;
}


/**
 * Base class for charts
 */
export class Chart implements IChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Chart.Chart';
  readonly $type: string = Chart.$type;
  Id: string;
  ChartDisplayOptions: IChartDisplayOptions;
  ExportableAsImageSettings: ExportSettings;
  ExportableAsExcelSettings: ExportSettings;
  ExportableAsHtmlTableSettings: ExportSettingsHtmlTable;
}


/**
 */
export class GeoCoordinate implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Chart.Series.GeoCoordinate';
  readonly $type: string = GeoCoordinate.$type;
  Altitud?: number;
  Longitud?: number;
  Latitud?: number;
  Velocidad?: number;
}


/**
 * A value for the series
 */
export interface ISerieValue extends ITypedObject {
  Value: any;
  ValueY: any;
  Id: string;
}


/**
 * Series value
 */
export class SerieValue implements ISerieValue {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Chart.Series.SerieValue';
  readonly $type: string = SerieValue.$type;
  Id: string;
  Value: any;
  ValueY: any;
}


/**
 * A data series
 */
export interface ISerie extends ICssClassesProperty, ITypedObject {
  Name: string;
  Description: string;
  Values: { [id: string]: ISerieValue };
  Id: string;
}


/**
 */
export interface ISerieMetadata extends ISerieValue, ICssClassesProperty, ITypedObject {
  Name: string;
  Description: string;
}


/**
 */
export interface IChartAction extends ITypedObject {
  Title: string;
  OnClickCommands: { [id: string]: ICommand };
  Position: string;
  Id: string;
}


/**
 * It is an abstraction to manage the possible results of an image export
 */
export interface IChartExportingResult extends ITypedObject {
}


/**
 * Resultado de la exportación de la gráfica: tabla en html
 */
export class ChartExportingResultHtmlTable implements IChartExportingResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Exporting.ChartExportingResultHtmlTable';
  readonly $type: string = ChartExportingResultHtmlTable.$type;
  Html: string;
}


/**
 * Chart Exporting Result: Batch Task Retrieve a Batch Task with the Export Execution Status
 */
export class ChartExportingResultBatchTask implements IChartExportingResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Exporting.ChartExportingResultBatchTask';
  readonly $type: string = ChartExportingResultBatchTask.$type;
  BatchTaskInfo: BatchTaskInfo;
}


/**
 * Chart Exporting Result: Batch Task Retrieve a Batch Task with CORE_FILE generated
 */
export class ChartExportingResultFile implements IChartExportingResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Exporting.ChartExportingResultFile';
  readonly $type: string = ChartExportingResultFile.$type;
  File: any;
}


/**
 * Chart Exporting Result: Batch Task Retrieve a Batch Task with File Ref
 */
export class ChartExportingResultFileRef implements IChartExportingResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.Dto.Exporting.ChartExportingResultFileRef';
  readonly $type: string = ChartExportingResultFileRef.$type;
  FileRef: string;
}


/**
 */
export class SingleNumberChartDisplayOptions implements IChartDisplayOptions, ITooltipChartDisplayOption {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.SingleNumberChart.SingleNumberChartDisplayOptions';
  readonly $type: string = SingleNumberChartDisplayOptions.$type;
  Color: string;
  Hidden: boolean;
  TooltipText: string;
  TooltipDisabled: boolean;
}


/**
 * The chart type must only have a single serie
 */
export interface ISingleSerieChart extends ITypedObject {
}


/**
 */
export interface ISingleNumberChart extends IChart, ISingleSerieChart, ITypedObject {
  ValueSeries: string;
  Description: string;
}


/**
 */
export class SingleNumberChart extends Chart implements ISingleNumberChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.SingleNumberChart.SingleNumberChart';
  readonly $type: string = SingleNumberChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeries: string;
  Description: string;
}


/**
 * The chart type can have multiple series
 */
export interface IMultipleSeriesChart extends ITypedObject {
}


/**
 * The Bar Chart
 */
export interface IBubbleChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * The Bar Chart
 */
export class BubbleChart extends Chart implements IBubbleChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.BubbleChart.BubbleChart';
  readonly $type: string = BubbleChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeriesId: string;
  LabelSeriesId: string;
}


/**
 * See https://swimlane.github.io/ngx-charts/#/ngx-charts/bubble-chart See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bubble-chart.md
 */
export class BubbleChartDisplayOptions extends NGXChartDisplayOptions implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.BubbleChart.BubbleChartDisplayOptions';
  readonly $type: string = BubbleChartDisplayOptions.$type;
  HideXAxis: boolean;
  HideYAxis: boolean;
  ShowXAxis: boolean;
  ShowYAxis: boolean;
  ShowXAxisLabel: boolean;
  YAxisLabel: string;
  ShowYAxisLabel: boolean;
  XAxisLabel: string;
  MinRadius: number;
  MaxRadius: number;
  XScaleMin: number;
  XScaleMax: number;
  YScaleMin: number;
  YScaleMax: number;
}


/**
 */
export class RadarChartDisplayOptions extends ColorPaletteChartDisplayOption implements IColorPaletteChartDisplayOption, IChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.RadarChart.RadarChartDisplayOptions';
  readonly $type: string = RadarChartDisplayOptions.$type;
  Hidden: boolean;
}


/**
 * The Radar Chart
 */
export interface IRadarChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * Charts.js line chart  Configuration and examples shown at https://www.chartjs.org/samples/latest/
 */
export class RadarChart extends Chart implements IRadarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.RadarChart.RadarChart';
  readonly $type: string = RadarChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeries: string[];
  LabelSeriesId: string;
}


/**
 */
export class ProgressBarChartDisplayOptions implements IChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.ProgressBarChart.ProgressBarChartDisplayOptions';
  readonly $type: string = ProgressBarChartDisplayOptions.$type;
  BackgroundColor: string;
  ProgressColor: string;
  IndicatorColor: string;
  HideIndicator: boolean;
  IndicatorTextFormat: string;
  Hidden: boolean;
}


/**
 */
export interface IProgressBarChart extends IChart, ISingleSerieChart, ITypedObject {
}


/**
 * Progress Bar Chart
 */
export class ProgressBarChart extends Chart implements IProgressBarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.ProgressBarChart.ProgressBarChart';
  readonly $type: string = ProgressBarChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
}


/**
 */
export class PieChartDisplayOptions extends ColorPaletteChartDisplayOption implements IColorPaletteChartDisplayOption, ILegendChartDisplayOption, ITooltipChartDisplayOption, IChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.PieChart.PieChartDisplayOptions';
  readonly $type: string = PieChartDisplayOptions.$type;
  Labels: boolean;
  TrimLabels: boolean;
  ShowLegend: boolean;
  LegendTitle: string;
  LegendPosition: string;
  ExplodeSlices: boolean;
  Doughnut: boolean;
  Gradient: boolean;
  Translation: string;
  TooltipText: string;
  TooltipDisabled: boolean;
  Hidden: boolean;
}


/**
 * The Bar Chart
 */
export interface IPieChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * The Bar Chart
 */
export class PieChart extends Chart implements IPieChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.PieChart.PieChart';
  readonly $type: string = PieChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeriesId: string;
  LabelSeriesId: string;
}


/**
 */
export class MapLocationsDisplayOptions implements IChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.MapLocationsChart.MapLocationsDisplayOptions';
  readonly $type: string = MapLocationsDisplayOptions.$type;
  Hidden: boolean;
}


/**
 */
export interface IMapLocationsChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * Chart the Google Maps con ubicaciones
 */
export class MapLocationsChart extends Chart implements IMapLocationsChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.MapLocationsChart.MapLocationsChart';
  readonly $type: string = MapLocationsChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeriesId: string;
  LabelSeriesId: string;
}


/**
 */
export class CustomDispersionChartDisplayOptions implements IChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.CustomDispersionChart.CustomDispersionChartDisplayOptions';
  readonly $type: string = CustomDispersionChartDisplayOptions.$type;
  XAxisLabel: string;
  YAxisLabel: string;
  Hidden: boolean;
}


/**
 * The Dispersion Chart
 */
export interface ICustomDispersionChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * Charts.js scatter chart  Configuration and examples shown at https://www.chartjs.org/samples/latest/charts/scatter/basic.html
 */
export class CustomDispersionChart extends Chart implements ICustomDispersionChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.CustomDispersionChart.CustomDispersionChart';
  readonly $type: string = CustomDispersionChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeriesId: string;
  LabelSeriesId: string;
}


/**
 * The Bar Chart
 */
export interface IBarChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * The Bar Chart
 */
export class BarChart extends Chart implements IBarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.BarChart';
  readonly $type: string = BarChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
}


/**
 * The Bar Chart
 */
export class GroupedBarChart extends BarChart implements IBarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.GroupedBarChart';
  readonly $type: string = GroupedBarChart.$type;
  LabelSeriesId: string;
}


/**
 */
export class BarChartDisplayOptions extends NGXChartDisplayOptions implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.BarChartDisplayOptions';
  readonly $type: string = BarChartDisplayOptions.$type;
  HideXAxis: boolean;
  HideYAxis: boolean;
  Gradient: boolean;
  HideXAxisLabel: boolean;
  HideYAxisLabel: boolean;
  XAxisLabel: string;
  YAxisLabel: string;
  MaxXAxisTickLength: number;
  TrimYAxisTicks: boolean;
}


/**
 * The Grouped Horizontal Bar Chart See https://swimlane.github.io/ngx-charts/#/ngx-charts/bar-vertical-2d See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bar-charts/grouped-horizontal-bar-chart.md
 */
export class GroupedHorizontalBarChart extends GroupedBarChart implements IBarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.GroupedHorizontalBarChart';
  readonly $type: string = GroupedHorizontalBarChart.$type;
}


/**
 * See https://swimlane.github.io/ngx-charts/#/ngx-charts/bar-horizontal-2d See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bar-charts/grouped-horizontal-bar-chart.md
 */
export class GroupedHorizontalBarChartDisplayOptions extends BarChartDisplayOptions implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.GroupedHorizontalBarChartDisplayOptions';
  readonly $type: string = GroupedHorizontalBarChartDisplayOptions.$type;
}


/**
 * See https://swimlane.github.io/ngx-charts/#/ngx-charts/bar-vertical-2d See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bar-charts/grouped-vertical-bar-chart.md
 */
export class GroupedVerticalBarChartDisplayOptions extends BarChartDisplayOptions implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.GroupedVerticalBarChartDisplayOptions';
  readonly $type: string = GroupedVerticalBarChartDisplayOptions.$type;
}


/**
 * The Bar Chart
 */
export class SimpleBarChart extends Chart implements IBarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.SimpleBarChart';
  readonly $type: string = SimpleBarChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeriesId: string;
  LabelSeriesId: string;
}


/**
 * The Horizontal Bar Chart See https://swimlane.github.io/ngx-charts/#/ngx-charts/bar-horizontal See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bar-charts/horizontal-bar-chart.md
 */
export class SimpleHorizontalBarChart extends SimpleBarChart implements IBarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.SimpleHorizontalBarChart';
  readonly $type: string = SimpleHorizontalBarChart.$type;
}


/**
 * See https://swimlane.github.io/ngx-charts/#/ngx-charts/bar-horizontal See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bar-charts/horizontal-bar-chart.md
 */
export class SimpleHorizontalBarChartDisplayOptions extends BarChartDisplayOptions implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.SimpleHorizontalBarChartDisplayOptions';
  readonly $type: string = SimpleHorizontalBarChartDisplayOptions.$type;
}


/**
 * The Vertical Bar Chart See https://swimlane.github.io/ngx-charts/#/ngx-charts/bar-vertical See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bar-charts/vertical-bar-chart.md
 */
export class SimpleVerticalBarChart extends SimpleBarChart implements IBarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.SimpleVerticalBarChart';
  readonly $type: string = SimpleVerticalBarChart.$type;
}


/**
 * See https://swimlane.github.io/ngx-charts/#/ngx-charts/bar-vertical See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bar-charts/vertical-bar-chart.md
 */
export class SimpleVerticalBarChartDisplayOptions extends BarChartDisplayOptions implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.SimpleVerticalBarChartDisplayOptions';
  readonly $type: string = SimpleVerticalBarChartDisplayOptions.$type;
}


/**
 * The Grouped Vertical Bar Chart See https://swimlane.github.io/ngx-charts/#/ngx-charts/bar-vertical-2d See https://github.com/swimlane/ngx-charts/blob/master/docs/examples/bar-charts/grouped-vertical-bar-chart.md
 */
export class GroupedVerticalBarChart extends GroupedBarChart implements IBarChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.Barcharts.GroupedVerticalBarChart';
  readonly $type: string = GroupedVerticalBarChart.$type;
}


/**
 * The Area Chart
 */
export interface IAreaChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * Charts.js line chart  Configuration and examples shown at https://www.chartjs.org/samples/latest/
 */
export class AreaChart extends Chart implements IAreaChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.AreaChart.AreaChart';
  readonly $type: string = AreaChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  LabelSeriesId: string;
}


/**
 */
export class AreaChartDisplayOptions extends NGXChartDisplayOptions implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.AreaChart.AreaChartDisplayOptions';
  readonly $type: string = AreaChartDisplayOptions.$type;
  HideXAxis: boolean;
  HideYAxis: boolean;
  Gradient: boolean;
  HideXAxisLabel: boolean;
  HideYAxisLabel: boolean;
  XAxisLabel: string;
  YAxisLabel: string;
  MaxXAxisTickLength: number;
  TrimYAxisTicks: boolean;
  XScaleMin?: number;
  XScaleMax?: number;
  YScaleMin?: number;
  YScaleMax?: number;
  TimeLine?: boolean;
}


/**
 */
export class LineChartDisplayOptions extends NGXChartDisplayOptions implements INGXChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.LineChart.LineChartDisplayOptions';
  readonly $type: string = LineChartDisplayOptions.$type;
  HideXAxis: boolean;
  HideYAxis: boolean;
  Gradient: boolean;
  HideXAxisLabel: boolean;
  HideYAxisLabel: boolean;
  XAxisLabel: string;
  YAxisLabel: string;
  MaxXAxisTickLength: number;
  TrimYAxisTicks: boolean;
  XScaleMin?: number;
  XScaleMax?: number;
  YScaleMin?: number;
  YScaleMax?: number;
  TimeLine?: boolean;
}


/**
 * The Bar Chart
 */
export interface ILineChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * Charts.js line chart  Configuration and examples shown at https://www.chartjs.org/samples/latest/
 */
export class LineChart extends Chart implements ILineChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.LineChart.LineChart';
  readonly $type: string = LineChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeries: string[];
  LabelSeriesId: string;
}


/**
 * The Inversal Bar Chart
 */
export interface IInversalLineChart extends IChart, IMultipleSeriesChart, ITypedObject {
}


/**
 * Charts.js line chart  Configuration and examples shown at https://www.chartjs.org/docs/latest/charts/line.html
 */
export class InversalLineChart extends Chart implements IInversalLineChart {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.InversalLineChart.InversalLineChart';
  readonly $type: string = InversalLineChart.$type;
  ChartDisplayOptions: IChartDisplayOptions;
  ValueSeries: string[];
  LabelSeriesId: string;
}


/**
 */
export class InversalLineChartDisplayOptions extends ColorPaletteChartDisplayOption implements IColorPaletteChartDisplayOption, IChartDisplayOptions {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Charts.ChartTypes.InversalLineChart.InversalLineChartDisplayOptions';
  readonly $type: string = InversalLineChartDisplayOptions.$type;
  Hidden: boolean;
  YScaleMin?: number;
  YScaleMax?: number;
  XAxisLabel: string;
  YAxisLabel: string;
}


/**
 * Persistent context for a Batch Operation.  Can be used to store data, results, etc.
 */
export class BatchContext implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.BatchContext';
  readonly $type: string = BatchContext.$type;
  Progress: number;
  RequestedState: string;
  ProgressMessage: string;
  ErrorMessage: string;
  Sandbox: any;
  Result: any;
  UserOutputMessages: string[];
}


/**
 * Posible states for a Batch Queue
 */
export class BatchState implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.BatchState';
  public static readonly PAUSED: string = 'PAUSED';
  public static readonly WAITING: string = 'WAITING';
  public static readonly COMPLETED: string = 'COMPLETED';
  public static readonly CANCELED: string = 'CANCELED';
  public static readonly ERROR: string = 'ERROR';
  public static readonly RUNNING: string = 'RUNNING';
  readonly $type: string = BatchState.$type;
}


/**
 */
export enum CoreBatchActionEnum {
  PAUSE = 0,
  CANCEL = 1,
  RESUME = 2
}


/**
 * Do some actions on a task from a Command. This command allows Views using JsonPathEvaluate
 */
export class CoreBatchActionTaskCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Command.CoreBatchActionTaskCommand';
  readonly $type: string = CoreBatchActionTaskCommand.$type;
  Action: CoreBatchActionEnum;
  QueueId: string;
  OnCloseCommands: { [id: string]: ICommand };
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Schedule a task from a Command. This command allows Views using JsonPathEvaluate
 */
export class CoreBatchScheduleTaskCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Command.CoreBatchScheduleTaskCommand';
  readonly $type: string = CoreBatchScheduleTaskCommand.$type;
  PluginId: string;
  Arguments: any;
  UniqueForCurrentPerson: boolean;
  HideProgressOnSchedule: boolean;
  OnCloseCommands: { [id: string]: ICommand };
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Visualiza una barra de progreso con el avance de una tarea batch. Es compatible con los siguientes comandos basados en tareas batch: * CoreCreateReportCommand * CoreExecuteTemplateCommand Para ello usar task = null
 */
export class CoreBatchSeeProgressCommand implements ICommand {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Command.CoreBatchSeeProgressCommand';
  readonly $type: string = CoreBatchSeeProgressCommand.$type;
  Job: BatchTaskInfo;
  OnCloseCommands: { [id: string]: ICommand };
  OnCompleteCommands: { [id: string]: ICommand };
  Id: string;
  Weight: number;
  Sync: boolean;
  Async: boolean;
}


/**
 * Esto se usa para homogeneziar el pintado de las etiquetas de estado en backend y frontend!
 */
export class BatchStatusDictionary implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Dto.BatchStatusDictionary';
  public static readonly PAUSED: string = 'Pausado';
  public static readonly WAITING: string = 'Esperando';
  public static readonly COMPLETED: string = 'Completado';
  public static readonly CANCELED: string = 'Cancelado';
  public static readonly ERROR: string = 'Error';
  public static readonly RUNNING: string = 'Ejecutando';
  readonly $type: string = BatchStatusDictionary.$type;
  StatusDict: { [id: string]: string };
}


/**
 */
export class BatchItemResult implements ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Dto.BatchItemResult';
  readonly $type: string = BatchItemResult.$type;
  Id: string;
  Title: string;
  Description: string;
  Icon: string;
  File: UploadedFile;
  CssClasses: string[];
}


/**
 * DTO with all the info for a batch task
 */
export class BatchTaskInfo implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Dto.BatchTaskInfo';
  readonly $type: string = BatchTaskInfo.$type;
  QueueId?: string;
  PluginId: string;
  Arguments: string;
  Job: CORE_QUEUE;
  CurrentExecution: CurrentExecutionInfo;
  HasResult: boolean;
  Executable: boolean;
  Pausable: boolean;
  Cancelable: boolean;
  Reexecutable: boolean;
  Title: string;
  Subtitle: string;
  ShowIdentifier: boolean;
  ShowInfo: boolean;
  ShowInfoWhenRunning: boolean;
  ShowBar: boolean;
}


/**
 * Interface used to mark a result for a BatchedTask
 */
export interface IBatchTaskResult extends ITypedObject {
}


/**
 */
export class BatchTaskResultFileRef implements IBatchTaskResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Dto.BatchTaskResultFileRef';
  readonly $type: string = BatchTaskResultFileRef.$type;
  FileRef: string;
  DocumentType: string;
}


/**
 */
export class BatchTaskResultUploadedFiles implements IBatchTaskResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Dto.BatchTaskResultUploadedFiles';
  readonly $type: string = BatchTaskResultUploadedFiles.$type;
  Files: { [id: string]: BatchItemResult };
}


/**
 */
export class BatchTaskResultWordAndPdfUploadedFile extends BatchTaskResultUploadedFiles implements IBatchTaskResult {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Dto.BatchTaskResultWordAndPdfUploadedFile';
  readonly $type: string = BatchTaskResultWordAndPdfUploadedFile.$type;
}


/**
 * All router calls must return a webservice response.
 */
export class WebServiceResponse implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Router.WebServiceResponse';
  readonly $type: string = WebServiceResponse.$type;
  result: any;
  error: NormalizedException;
  commands: ICommand[];
}


/**
 * All router calls must return a webservice response.
 */
export class WebServiceResponseTyped<TResponseType> extends WebServiceResponse implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Router.WebServiceResponseTyped\`1';
  readonly $type: string = WebServiceResponseTyped.$type;
  result: TResponseType;
}


/**
 */
export class StyleConstants implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Kernel.StyleConstants';
  public static readonly BTN_PRIMARY: string = 'o-btn__primary';
  public static readonly BTN_PRIMARY_NEW: string = 'o-btn__primary--new';
  public static readonly BTN_SECONDARY: string = 'o-btn__secondary';
  public static readonly BTN_ACCENT: string = 'o-btn__accent';
  public static readonly BTN_DANGER: string = 'o-btn__danger';
  public static readonly BTN_LINK: string = 'o-btn__link';
  public static readonly BTN_TABLE_SECONDARY: string = 'o-btn__secondary--table';
  public static readonly BTN_TABLE_ACCENT: string = 'o-btn__accent--table';
  public static readonly BTN_BOOL: string = 'o-btn-bool';
  public static readonly BTN_ACCENT_DOCEMPTY: string = 'o-btn__accent--empty';
  public static readonly BTN_ACCENT_DOCFULL: string = 'o-btn__accent--full';
  public static readonly COL_2: string = 'l-check-col_2';
  public static readonly COL_3: string = 'l-check-col_3';
  public static readonly COL_4: string = 'l-check-col_4';
  public static readonly FIELDSET_CENTER: string = 'l-btn-fieldset';
  public static readonly FIELDSET_LEFT: string = 'l-btn-fieldset--left';
  public static readonly FIELDSET_RIGHT: string = 'l-btn-fieldset--right';
  public static readonly QUESTIONNAIRE_INPUT: string = 'l-questionnaire-flexend';
  public static readonly CHECK_SWITCH: string = 'check-switch';
  public static readonly INLINE_RADIO: string = 'l-inline-radio';
  public static readonly SIZE_FORM: string = 'flex-col-sm-12 flex-col-md-12 flex-col-lg-8';
  public static readonly LIST_ROW_ENABLED: string = 'enable';
  public static readonly LIST_ROW_DISABLED: string = 'disable';
  public static readonly COL_RADIO: string = 'l-col-radio';
  public static readonly ICON_ADD: string = 'icon-add';
  public static readonly ICON_REMOVE: string = 'icon-remove';
  public static readonly REGULAR_FONT_FOR_LABEL: string = 'u-regular-label';
  public static readonly BOLD_FONT_FOR_LABEL: string = 'u-bold-regular-label';
  public static readonly BOLD_FONT_FOR_LABEL_H3: string = 'u-bold-regular-label-h3';
  public static readonly STYLE_LABEL_H1: string = 'u-label-h1';
  public static readonly STYLE_LABEL_H2: string = 'u-label-h2';
  public static readonly STYLE_LABEL_H3: string = 'u-label-h3';
  public static readonly STYLE_LABEL_P: string = 'u-label-p';
  public static readonly FORM_READONLY_FORMAT: string = 'form-readonly-format';
  public static readonly BOLD_FONT_FOR_LABEL_TITLE: string = 'u-bold-regular-label-title';
  public static readonly BOLD_FONT_FOR_LABEL_TITLE_U_MT: string = 'u-bold-regular-label-title u-mt-small';
  public static readonly BOLD_FONT_FOR_LABEL_CONTENT: string = 'u-bold-regular-label-content nolabeltitle';
  public static readonly BOLD_FONT_FOR_LABEL_CONTENT_TITLE: string = 'u-bold-regular-label-content';
  public static readonly LABEL_CONTENT_JUSTIFY: string = 'u-justify-label';
  readonly $type: string = StyleConstants.$type;
}


/**
 * Base clase para todos los eventos Callback.
 */
export interface ICallbackHandlerEventArgs extends ITypedObject {
  StopPropagation: boolean;
}


/**
 * Base clase para todos los eventos Callback.
 */
export class CallbackHandlerEventArgs implements ICallbackHandlerEventArgs {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Kernel.Callback.CallbackHandlerEventArgs';
  readonly $type: string = CallbackHandlerEventArgs.$type;
  StopPropagation: boolean;
}


/**
 * To group callback typed's
 */
export interface ICallbackTyped extends ISignedObject, ITypedObject {
}


/**
 * A typed callback
 */
export class CallbackTyped implements ICallbackTyped {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Kernel.Callback.CallbackTyped';
  readonly $type: string = CallbackTyped.$type;
  ArgumentMapExpression: any;
  TargetTypeFqdn: string;
  MethodTypeFqdn: string;
  Method: string;
  Arguments: any[];
  Weight: number;
  Signature: string;
  SaltType?: any;
}


/**
 */
export class FormElementDatePickerTimeZoneInfo implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementDateTimePicker+FormElementDatePickerTimeZoneInfo';
  readonly $type: string = FormElementDatePickerTimeZoneInfo.$type;
  IanaName: string;
  StandardName: string;
  WindowsId: string;
  DisplayName: string;
  DaylightName: string;
}


/**
 */
export class Coordinates implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementMapInput+Coordinates';
  readonly $type: string = Coordinates.$type;
  Latitude?: number;
  Longitude?: number;
}


/**
 * Configuración de filas
 */
export class FormElementTableRowConfig implements ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementTable+FormElementTableRowConfig';
  readonly $type: string = FormElementTableRowConfig.$type;
  Cells: { [id: string]: FormElementTableCellConfig };
  CssClasses: string[];
}


/**
 * Configuración de columnas
 */
export class FormElementTableCellConfig implements ICssClassesProperty {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Form.Elements.FormElementTable+FormElementTableCellConfig';
  readonly $type: string = FormElementTableCellConfig.$type;
  ComponentId: string;
  CssClasses: string[];
  Colspan?: number;
  Headers: string;
  Rowspan?: number;
}


/**
 * Payload del token JWT
 */
export class Payload implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Crypto.Services.StringSignerJwtService+Payload';
  readonly $type: string = Payload.$type;
  exp: number;
  iat: number;
  st: string;
  data: string;
}


/**
 * The Current Execution Class
 */
export class CurrentExecutionInfo implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Application.Core.Module.Batch.Dto.BatchTaskInfo+CurrentExecutionInfo';
  readonly $type: string = CurrentExecutionInfo.$type;
  Status?: any;
  Log: string;
  LastRun?: number;
  CreatedAt?: number;
  StartedAt?: number;
  ElapsedTime: any;
  BatchCount?: number;
  ProgressMessage: string;
  Context: BatchContext;
  Configuration: any;
  ErrorMessage: string;
  Progress: number;
  UserOutputMessages: string[];
}


/**
 */
export enum CurrentExecutionStatus {
  PAUSED = 0,
  WAITING = 1,
  COMPLETED = 2,
  CANCELED = 3,
  ERROR = 4,
  RUNNING = 5
}


/**
 */
export class Tuple<T1, T2> implements ITypedObject {
  public static readonly $type: string = 'System.Tuple\`2';
  readonly $type: string = Tuple.$type;
  Item1: T1;
  Item2: T2;
}


/**
 */
export class KeyValuePair<TKey, TValue> implements ITypedObject {
  public static readonly $type: string = 'System.Collections.Generic.KeyValuePair\`2';
  readonly $type: string = KeyValuePair.$type;
  Key: TKey;
  Value: TValue;
}


/**
 */
export enum Precision {
  Years = 0,
  Months = 1,
  Days = 2,
  Hours = 3,
  Minutes = 4,
  Seconds = 5,
  Milliseconds = 6,
  Ticks = 7
}


/**
 */
export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

